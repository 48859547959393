<template>
	
</template>

<script>
    export default {
        name: 'News',
    };
</script>

<style scoped>

</style>