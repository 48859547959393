interface GroupDto {
    course?: number;
    name?: string;
    id: string;
}

export class Group {
    public static fromDto(dto: GroupDto): Group {
        return new Group(
            dto.course,
            dto.name,
            dto.id,
        );
    }

    public course?: number;
    public name?: string;
    public id: string;

    constructor(
        course?: number,
        name?: string,
        id: string = '-1',
    ) {
        this.course = course;
        this.name = name;
        this.id = id;
    }

    public getName(): string | undefined {
        return this.name;
    }

    public getCourse(): number | undefined {
        return this.course;
    }
}
