<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <v-card class="fill-height" flat v-if="selectItem != null">
      <v-app-bar
          color="primary"
          dark
          elevation="0">
        <v-btn @click="closeDrawer()" class="ma-1" small icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <p class="mt-10 mb-10">Профиль</p>
      </v-app-bar>
      <v-divider></v-divider>
      <v-avatar
          tile
          size="256"
      >
        <v-img
            :aspect-ratio="0.8"
            :src="profilePhoto"
            class="pa-1">
        </v-img>
      </v-avatar>
      <v-divider></v-divider>
      <v-row class="justify-center ma-2">
        <div>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn class="mx-2"
                     fab
                     dark
                     small
                     outlined
                     color="primary"
                     v-on="on"
                     @click="openUserProfile">
                <v-icon dark>mdi-account-outline</v-icon>
              </v-btn>
            </template>
            <div>Открыть профиль</div>
          </v-tooltip>
        </div>

        <div v-if="emptyStatement">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn class="mx-2"
                     fab
                     dark
                     small
                     outlined
                     color="primary"
                     v-on="on"
                     @click="openStatementDialog">
                <v-icon dark>mdi-file-outline</v-icon>
              </v-btn>
            </template>
            <div>Открыть заявление</div>
          </v-tooltip>
        </div>
        <div v-else-if="$store.getters.getIsStaff || $store.getters.getIsSuperUser">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn class="mx-2"
                     fab
                     dark
                     small
                     outlined
                     color="success"
                     v-on="on"
                     @click="openLoadStatementDialog">
                <v-icon dark>mdi-file-plus-outline</v-icon>
              </v-btn>
            </template>
            <div>Добавить заявление</div>
          </v-tooltip>
        </div>
      </v-row>
      <v-divider></v-divider>
      <v-list subheader dense>

        <div class="text-center body-1 mt-2" v-if="selectItem.fullname != null">
          <p>{{ selectItem.fullname }}</p>
        </div>
        <div v-if="selectItem.group != null">
          <v-list-item v-if="selectItem.group.faculty != null">
            <v-list-item-icon>
              <v-icon color="primary">mdi-bank</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ selectItem.group.faculty.shortname }}</v-list-item-title>
              <v-list-item-subtitle>Факультет</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="selectItem.group.name != null">
            <v-list-item-icon>
              <v-icon color="primary">mdi-library</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ selectItem.group.name }}</v-list-item-title>
              <v-list-item-subtitle>Группа</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </div>
        <v-list-item  v-if="selectItem.elUnionCard">
          <v-list-item-icon>
            <v-icon color="primary">mdi-credit-card-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ selectItem.elUnionCard }}</v-list-item-title>
            <v-list-item-subtitle>Номер профбилета</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="selectItem.studentNumber">
          <v-list-item-icon>
            <v-icon color="primary">mdi-counter</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ selectItem.studentNumber }}</v-list-item-title>
            <v-list-item-subtitle>Номер зачётки</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="selectItem.birthday !== null">
          <v-list-item-icon>
            <v-icon color="primary">mdi-cake-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ getEntranceDate(selectItem.birthday) }}</v-list-item-title>
            <v-list-item-subtitle>День рождения</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="selectItem.memberFrom !== null">
          <v-list-item-icon>
            <v-icon color="primary">mdi-calendar-check</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> {{ getEntranceDate(selectItem.memberFrom) }}</v-list-item-title>
            <v-list-item-subtitle>Дата вступления</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="selectItem.memberEnd !== null">
          <v-list-item-icon>
            <v-icon color="primary">mdi-calendar-remove</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ getEntranceDate(selectItem.memberEnd) }}</v-list-item-title>
            <v-list-item-subtitle>Дата выхода</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="selectItem.phone != null">
          <v-list-item-icon>
            <v-icon color="primary">mdi-phone</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ selectItem.phone }}</v-list-item-title>
            <v-list-item-subtitle>Номер телефона</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="selectItem.email !== null">
          <v-list-item-icon>
            <v-icon color="primary">mdi-email</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title style="overflow: scroll; white-space: pre-line">{{ selectItem.email }}
            </v-list-item-title>
            <v-list-item-subtitle>E-mail</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
            v-if='selectItem.commentary !== null && selectItem.commentary !=="\n" && selectItem.commentary !== ""'>
          <v-list-item-icon>
            <v-icon color="primary">mdi-cake-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title style="overflow: scroll; white-space: pre-line"> {{ selectItem.commentary }}
            </v-list-item-title>
            <v-list-item-subtitle>Комментарий</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <div v-if="isDebts(selectItem)">
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="primary">mdi-cash</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Долги</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list subheader dense
                  v-if="selectItem.entranceSemester !== null">
            <v-list-item v-for="item in selectItem.debts" class="ma-n2">
              <v-list-item-icon class="mr-1">
                <v-icon right>mdi-circle-small</v-icon>
              </v-list-item-icon>
              <v-list-item-content v-if="item.type !== 'UNION_CARD'">
                <v-list-item-title>{{ getSemesterDebts(item, selectItem.entranceSemester.year) }}
                  семестр
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ item.item.name }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content v-else>
                <v-list-item-title>
                  Профсоюзный билет
                </v-list-item-title>
                <v-list-item-subtitle>
                  Вступительный взнос
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list v-else>
            <v-list-item v-for="item in selectItem.debts" v-if="item.type !== 'UNION_CARD'" class="mt-n2">
              <v-icon right>mdi-circle-small</v-icon>
              <v-list-item-content>
                <v-list-item-subtitle> {{ item.item.name }}
                </v-list-item-subtitle>
              </v-list-item-content>

            </v-list-item>
          </v-list>
        </div>

      </v-list>
    </v-card>
    <dialog-load-statement :key="indexDS" :select-profile="selectItem"></dialog-load-statement>

    <!--Диалог "Заявление"-->
    <v-dialog
        v-model="showStatementDialog"
        hide-overlay
        :fullscreen="true"
    >
      <dialog-statement
          :statementSrc="statementSrc"
          @closeDialog="showStatementDialog=false"></dialog-statement>
    </v-dialog>
  </div>
</template>

<script>
import DialogLoadStatement from '../system/staff/contingent/components/DialogLoadStatement.vue';
import DialogStatement from '../system/staff/contingent/components/DialogStatement.vue';
import moment from 'moment';

const helper = require('../../utils/helpers');

export default {
  name: 'ProfileDrawer',
  props: {
    selectItem: {
      type: Object,
      default: {
        studentId: null,
        profileId: null,
        photo: null,
        sex: 'м',
        files: null,
        fullname: null,
        group: {
          faculty: null,
          course: 0,
          name: null,
        },
        studentNumber: null,
        phone: null,
        email: null,
        entranceSemester: null,
        memberFrom: null,
        memberEnd: null,
        birthday: null,
        commentary: null,
        elUnionCard: null,
      },
    },
  },
  data() {
    return {
      showStatementDialog: false,
      statementSrc: null,
      indexDS: 0,
    };
  },
  methods: {
    closeDrawer() {
      this.$emit('closeProfile');
    },
    openUserProfile() {
      if (this.selectItem.studentId != null) {
        this.$router.push({name: 'user-profile', params: {userId: this.selectItem.studentId}});
      }
    },
    openStatementDialog() {
      if (this.selectItem === null) {
        this.statementSrc = null;
      }

      if (this.selectItem.files) {
        const files = this.selectItem.files;
        const filteredFiles = files.filter((file) => file.category === 'st');
        if (filteredFiles.length > 0) {
          filteredFiles.sort((a, b) => b.id - a.id);
          this.statementSrc = filteredFiles[0].file;
        } else {
          this.statementSrc = null; // Если нет файлов категории 'st'
        }
      } else {
        this.statementSrc = null;
      }
      this.showStatementDialog = true;
    },
    openLoadStatementDialog() {
      this.indexDS++;
      this.$store.commit('setShowLoadStatement', true);
    },
    getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min)) + min;
    },
    getEntranceDate(item) {
      if (item === null) {
        return '';
      }
      return moment(item).locale('ru').format('L');
    },
    getSemesterDebts(item, year) {
      return helper.getSemesterNumber(item, year);
    },
    isDebts(item) {
      return item.debts.length !== 0;
    },
  },
  computed: {
    profilePhoto: {
      get() {
        if (this.selectItem === null) {
          return null;
        }

        if (this.selectItem.photo === null) {
          if (this.selectItem.sex === 'м') {
            return require('@/assets/avatar/male/' + this.getRandomInt(1, 10) + '.jpg');
          } else {
            return require('@/assets/avatar/female/' + this.getRandomInt(1, 7) + '.jpg');
          }
        } else {
          return this.selectItem.photo;
        }
      },
    },

    emptyStatement() {
      if (this.selectItem === null) {
        return false;
      }

      if (this.selectItem.files) {
        for (const file of this.selectItem.files) {
          if (file.category === 'st') {
            return true;
          }
        }
        return false;
      }
    },


  },
  components: {
    dialogLoadStatement: DialogLoadStatement,
    dialogStatement: DialogStatement,
  },
};
</script>

<style scoped>
img {
  display: block;
  max-width: 80%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  position: center;
}

</style>