<template>
    <v-dialog
            v-model="showDialog"
            max-width="1000"
            max-height="1000">
        <v-card>
            <v-card-title>
                <v-btn
                        @click="showDialog=false"
                        icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                Загрузка заявления
            </v-card-title>
            <v-card-text class="text-center">
                <div class="text-left">
                    <div class="subtitle-2">
                        Ф.И.О: {{getFullname}}
                    </div>
                    <div class="subtitle-2">
                        Номер зачётки: {{getStudentNumber}}
                    </div>
                    <div class="subtitle-2">
                        Название файла: {{getChosenFileName}}
                    </div>
                </div>
                <div>
                    <v-alert
                            dense
                            type="warning"
                            :value="showWarningAlert"
                            transition="scale-transition">Номер зачётки отличается от названия файла. Если вы уверены,
                        что всё верно,
                        то нажмите кнопку
                        "Загрузить"
                    </v-alert>
                    <v-alert
                            :value="errorAlert"
                            dense
                            type="error"
                            transition="scale-transition">
                        {{errorText}}
                    </v-alert>
                    <vue-cropper
                            v-model="croppa"
                            :width="700"
                            :height="300"
                            canvas-color="transparent"
                            placeholder-color="#000"
                            placeholder=""
                            :placeholder-font-size="16"
                            :disable-rotation="true"
                            :disable-drag-to-move="true"
                            :disable-scroll-to-zoom="true"
                            :disable-pinch-to-zoom="true"
                            :prevent-white-space="false"
                            :show-remove-button="false"
                            initial-size="contain"
                            accept=".jpg,.png"
                            style="position: relative"
                            class="croppa-container"
                            @file-choose="chooseImg()"
                            @file-size-exceed="showError('Размер файла не может превышать 1Мб')"
                            @file-type-mismatch="showError('Формат файла должен быть .jpg и .png')"
                            @new-image="readyToLoad()"
                            @new-image-drawn=""
                    >
                        <div v-if="disabledBtn" class="headline">Перетащите или выберите файл</div>
                        <div v-else class="headline">&nbsp;</div>
                    </vue-cropper>
                    <v-btn v-show="disabledBtn" color="primary" outlined class="ma-4" @click="croppa.chooseFile()">
                        <v-icon>
                            mdi-download-outline
                        </v-icon>
                        Выбрать файл
                    </v-btn>
                    <v-btn
                            v-show="!disabledBtn"
                            class="ma-2"
                            color="error"
                            outlined
                            @click="removeImg()">
                        <v-icon>
                            mdi-delete-outline
                        </v-icon>
                        Удалить
                    </v-btn>
                </div>
                <v-btn
                        :disabled="readyToLoadFlag"
                        class="ma-2"
                        color="primary"
                        :loading="loaderUploadBtn"
                        @click="upload()"
                >
                    Загрузить
                </v-btn>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import Vue from 'vue';
    import Croppa from 'vue-croppa';

    Vue.use(Croppa, {componentName: 'vue-cropper'});
    export default {
        name: 'DialogLoadStatement',
        props: {
            selectProfile: null,
        },
        data() {
            return {
                croppa: {},
                disabledBtn: true,
                readyToLoadFlag: true,
                errorAlert: false,
                errorText: '',
                loaderUploadBtn: false,
                chosenFileName: 'Нет файла',
            };
        },
        methods: {
            removeImg() {
                this.croppa.remove();
                this.disabledBtn = true;
                this.readyToLoadFlag = true;
            },
            chooseImg() {
                this.errorAlert = false;
                this.disabledBtn = false;
            },
            readyToLoad() {
                this.errorAlert = false;
                this.readyToLoadFlag = false;
            },
            showError(str) {
                this.errorAlert = true;
                this.errorText = str;
                this.removeImg();
            },
            upload() {
                this.loaderUploadBtn = true;
                this.$store.dispatch('loadFileStatement', {
                    category: 'st',
                    access_level: 'pf',
                    owner: this.selectProfile.profileId,
                    file: this.croppa.chosenFile,
                    description: 'Заявление о вступлении',
                }).then(() => {
                    this.$store.dispatch('updateContingentSelectItem', this.$store.getters.getContingentSelectItem).finally(() => {
                        this.loaderUploadBtn = false;
                        this.$store.commit('setShowLoadStatement', false);
                        this.removeImg();
                    });
                });
            },
        },
        computed: {
            showDialog: {
                get() {
                    return this.$store.getters.getShowLoadStatement;
                },
                set() {
                    this.$store.commit('setShowLoadStatement', false);
                },
            },
            getChosenFileName() {
                if (!this.croppa.imageSet) {
                    return 'Нет файла';
                }
                let i = 0;
                for (const charStr of this.croppa.chosenFile.name) {
                    if (charStr === '.') {
                        break;
                    }
                    i++;
                }
                const lengthName = this.croppa.chosenFile.name.length;
                return this.croppa.getChosenFile().name.substr(0, i);
            },
            showWarningAlert() {
                if (!this.croppa.imageSet) {
                    return false;
                }

                if (this.selectProfile !== null) {
                    return this.getChosenFileName !== this.selectProfile.studentNumber;
                } else {
                    return false;
                }
            },
            getFullname() {
                if (this.selectProfile !== null) {
                    return this.selectProfile.fullname;
                } else {
                    return '';
                }
            },
            getStudentNumber() {
                if (this.selectProfile !== null) {
                    return this.selectProfile.studentNumber;
                } else {
                    return '';
                }
            },
        },
    };
    // TODO Заявление может добавлять только пользователь с допуском
</script>

<style scoped>
    .croppa-container {
        background-color: rgba(0, 39, 114, 0);
        border: 2px solid grey;
        border-radius: 8px;
    }

    .croppa-container:hover {
        opacity: 1;
        background-color: rgba(2, 145, 249, 0.01);
    }
</style>