import {HTTP} from '@/plugins/axios';

export default {
    state: {
        showLoadStatementDialog: false,
    },
    getters: {
        getShowLoadStatement(state: any) {
            return state.showLoadStatementDialog;
        },
    },
    mutations: {
        setShowLoadStatement(state: any, payload: any) {
            state.showLoadStatementDialog = payload;
        },
    },
    actions: {
        async loadFileStatement({commit}: any, payload: any) {
            const fd = new FormData();
            fd.append('file', payload.file, 'file.jpg');
            fd.set('category', payload.category);
            fd.set('access_level', payload.access_level);
            fd.set('owner', payload.owner);
            fd.set('description', payload.description);
            await HTTP.post('/v/0.2/core/files/', fd, {
                headers: {'content-type': 'multipart/form-data'},
            });
        },
    },
};
