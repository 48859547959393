import {Faculty} from '@/model/dto/Faculty';

interface UserDto {
    availableFaculties?: Faculty[];
}

export class User {
    public static fromDto(dto: UserDto): User {
        return new User(
            dto.availableFaculties,
        );
    }

    public availableFaculties?: Faculty[];

    constructor(
        availableFaculties?: Faculty[],
    ) {
        this.availableFaculties = availableFaculties;
    }

    // Получить количество доступных факультетов
    public getCountAvailableFaculties(): number {
        return this.availableFaculties?.length || 0;
    }

    // Получить список доступных факультетов
    public getAvailableFaculties() {
        return this.availableFaculties;
    }
}
