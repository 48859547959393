import {HTTP} from '@/plugins/axios';

export default {
    state: {
        facultyList: null,
        groupList: null,
        semesterList: null,
    },
    getters: {
        getFacultyList(state: any) {
            return state.facultyList;
        },
        getGroupList(state: any) {
            return state.groupList;
        },
        getSemesterList(state: any) {
            return state.semesterList;
        },
    },
    mutations: {
        setFacultyList(state: any, payload: any) {
            state.facultyList = payload;
        },
        setGroupList(state: any, payload: any) {
            state.groupList = payload;
        },
        setSemesterList(state: any, payload: any) {
            state.semesterList = payload;
        },
    },
    actions: {
        async getFacultyList({commit}: any) {
            const AUTH_CONST: string = 'Authorization';
            HTTP.defaults.headers.common[AUTH_CONST] = 'Token ' + localStorage.getItem('user-token');
            try {
                await HTTP.get('/v/0.2/student/faculty').then((response) => {
                    commit('setAvailableFacultiesRest', response.data.results);
                });
            } catch (error) {
            }
        },
        async getGroupList({commit}: any, payload: any) {
            try {
                const http = await HTTP.get('/v/0.2/student/group', {
                    headers: {Authorization: 'Token ' + localStorage.getItem('user-token')},
                    params: {
                        faculty_id: payload.faculty_id,
                        limit: 1000,
                    },
                });
                commit('setGroupList', http.data.results);
            } catch (error) {
            }
        },
        async getSemesterList({commit}: any, payload: any) {
            try {
                const http = await HTTP.get('/v/0.2/student/semester/');
                commit('setSemesterList', http.data.results);
            } catch (error) {
            }
        },
    },
};
