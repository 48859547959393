<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-app id="inspire" class="white">
        <v-app-bar
                app
                absolute
                dark
                elevation="0"
                class="mr-auto profstyle-toolbar">
            <v-app-bar-nav-icon
                    @click="mainDrawer = !mainDrawer"></v-app-bar-nav-icon>
            <v-toolbar-title></v-toolbar-title>

            <div class="flex-grow-1"></div>
            <v-menu
                    v-model="profileValue"
                    offset-y
            >
                <template v-slot:activator="{on}">

                    <v-list-item class="col-2 text-right" style="cursor: pointer">
                        <v-list-item-title  v-on="on">{{getTitleFullname}}</v-list-item-title>
                        <v-list-item-avatar
                                v-on="on"
                        >
                            <v-img :src=mainPhoto></v-img>
                        </v-list-item-avatar>
                    </v-list-item>
                </template>
                <v-list>
                    <!--<v-list-item :to="{name:'profile'}">-->
                    <!--<v-list-item-title>Мой профиль-->
                    <!--</v-list-item-title>-->
                    <!--</v-list-item>-->
                    <!--<v-divider></v-divider>-->
                    <v-list-item @click="logOut">
                        <v-list-item-icon>
                            <v-icon>mdi-exit-to-app</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Выход
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>
        <v-main class="ma-1" v-if="getTitleFullname !== ''">
            <router-view></router-view>
        </v-main>
        <v-navigation-drawer
                v-model="mainDrawer"
                dark
                app
                floating
                class="profstyle-nav-drawer">
            <v-img src="../../assets/logoprofdstu.png"
                   class="mt-2"
                   contain
                   aspect-ratio="2.5"
            ></v-img>
            <v-list>
                <!--<v-list-item
                        :to="{name:'questionnaire'}">
                    <v-list-item-icon>
                        <v-icon>mdi-ticket-account</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Анкета</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>-->
                <v-list-item
                        v-if="false"
                        :to="{name:'my-profile'}">
                    <v-list-item-icon>
                        <v-icon>mdi-account</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Профиль</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <!--<v-list-item
                        :to="{name:'questionnaire'}">
                    <v-list-item-icon>
                        <v-icon>mdi-sale</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Скидки</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>-->
                <v-list-item
                        :to="{name:'contingent'}">
                    <v-list-item-icon>
                        <v-icon>mdi-table-large</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Контингент</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item
                        v-if="false"
                        :to="{name:'unionCard'}">
                    <v-list-item-icon>
                        <v-icon>mdi-ticket-account</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Профсоюзный билет</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
        <v-snackbar v-model="snackbar" :color="snackbarColor" @input="closeSnackbar">
            {{$store.getters.getTextAlertSnackbar}}
        </v-snackbar>
    </v-app>
</template>

<script>
    import * as Sentry from '@sentry/browser';

    export default {
        name: 'Main',
        data() {
            return {
                mainDrawer: false,
                profileValue: false,
                myId: -1,
                familyname: '',
                firstname: '',
                surname: '',
                titleFullname: '',
                student_number: '',
                mainPhoto: '',
                username: '',
            };
        },
        methods: {
            logOut() {
                localStorage.removeItem('user-token');
                this.$router.push('/');
                location.reload();
            },
            getRandomInt(min, max) {
                return Math.floor(Math.random() * (max - min)) + min;
            },
            closeSnackbar() {
                this.$store.commit('setIsShowAlertSnackbar', false);
            },
        },
        computed: {
            getTitleFullname() {
                if (this.familyname === '') {
                    return '';
                } else {
                    return this.familyname + ' ' + this.firstname.slice(0, 1) + '.' + this.surname.slice(0, 1) + '.';
                }
            },
            snackbar: {
                get() {
                    return this.$store.getters.getIsShowAlertSnackbar;
                },
                set() {
                    this.$store.commit('setIsShowAlertSnackbar', false);
                },
            },
            snackbarColor: {
                get() {
                    return this.$store.getters.getColorAlertSnackbar;
                },
            },
        },
        beforeCreate() {
            if (!localStorage.getItem('user-token')) {
                this.$router.push('/contingent');
            }

            this.$store.dispatch('loadMyProfile')
                .then(() => {
                        const myProfile = this.$store.getters.getMyProfile;

                        Sentry.setUser({
                            username: myProfile.user.username,
                            id: myProfile.user.id,
                            name: myProfile.fullname,
                            email: myProfile.email,
                        });

                        this.myId = myProfile.id;
                        this.familyname = myProfile.familyname;
                        this.firstname = myProfile.firstname;
                        this.surname = myProfile.surname;
                        this.student_number = myProfile.student_number;
                        if (myProfile.mainPhoto !== null) {
                            this.mainPhoto = myProfile.mainPhoto;
                        } else {
                            if (myProfile.user.username === 'admin') {
                                this.mainPhoto = require('@/assets/avatar.jpg');
                            } else {
                                if (myProfile.sex === 'м') {
                                    this.mainPhoto = require('@/assets/avatar/male/' + this.getRandomInt(1, 10) + '.jpg');
                                } else {
                                    this.mainPhoto = require('@/assets/avatar/female/' + this.getRandomInt(1, 7) + '.jpg');
                                }
                            }
                        }

                        if (!this.$store.getters.getIsStaff && !this.$store.getters.getIsSuperUser) {
                            this.$store.dispatch('loadAvailableFacultiesGql');
                        } else {
                            this.$store.dispatch('getFacultyList');
                        }
                    },
                );
        },
        created() {
            this.$store.dispatch('getSemesterList');
        },
    };
</script>

<style scoped>
    .profstyle-nav-drawer {
        background: linear-gradient(45deg, #1b2b57, #1c426c, #1f5684, #1e6696);
    }

    .profstyle-toolbar {
        background: linear-gradient(135deg, #1e6696, #1f5684, #024b88);
    }
</style>