import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/store';
import vuetify from './plugins/vuetify';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';
import ProfileDrawer from './components/share/ProfileDrawer.vue';
import FilterDrawer from './components/system/staff/contingent/components/FilterDrawer.vue';
import * as Sentry from '@sentry/vue';
import getEnv from './utils/getEnv';

Vue.config.productionTip = false;
Vue.component('cont-profile-drawer', ProfileDrawer);
Vue.component('cont-filter-drawer', FilterDrawer);

const sentryDsn = getEnv('VUE_APP_SENTRY_DSN');
if (sentryDsn) {
    if (process.env.NODE_ENV !== 'development') {
        console.info('<9239c2b1> Sentry enabled');
        Sentry.init({
            Vue,
            dsn: sentryDsn,
            environment: getEnv('VUE_APP_SENTRY_ENV'),
            integrations: [Sentry.browserTracingIntegration({router}), Sentry.replayIntegration()],
          
            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 1.0,
          
            // Capture Replay for 10% of all sessions,
            // plus for 100% of sessions with an error
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
          });
    }
}

new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount('#app');
