import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import ru from 'vuetify/src/locale/ru';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#024b88',
            },
        },
    },
    lang: {
        locales: {ru},
        current: 'ru',
    },
    icons: {
        iconfont: 'mdi',
        values: {
            sbp: 'M13.4,12.5l6.5-3.9l-8.8-5.3v8L4.7,7.3l0,10.5l6.4-3.9l0,8l8.7-5.3l-6.5-4L13.4,12.5z M15.5,8.5l-2.1,1.3l0-2.6L15.5,8.5zM9,12.5l-2,1.2l0-2.5L9,12.5z M13.4,17.8v-2.6l2.1,1.3L13.4,17.8z',
        },
    },
});

// primary: '#023e72',
