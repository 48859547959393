import {HTTP} from '@/plugins/axios';

export default {
    state: {},
    getters: {},
    mutations: {},
    actions: {
        async logIn({commit}: any, payload: any) {
            try {
                return await HTTP.post('v/0.2/core/login/', payload);
            } catch (e) {
                return e.response;
            }
        },
        async signUp({commit}: any, payload: any) {
            try {
                const user = await HTTP.post('v/0.2/student/register/', payload);
            } catch (e) {
            }
        },
    },
};
