import {Pagination} from '@/model/dto/Pagination';
import {StudentFilter} from '@/model/dto/StudentFilterDto';
import {HTTP} from '@/plugins/axios';
import {print} from 'graphql';

const AUTH_CONST: string = 'Authorization';
const CONTENT_TYPE_CONST: string = 'Content-Type';
const PRE_LOAD_CONTINGENT = require('../graphql/contingent/PreLoadContingent.gql');
const SET_STATUS_UNION_CARD = require('../graphql/union_card/SetStatusUnionCard.gql');
const GET_STATUS_UNION_CARD = require('../graphql/union_card/GetById.gql');
const GET_STUDENT_DEBTS = require('../graphql/contingent/GetStudentDebts.gql');
const GET_STUDENT_BY_ID = require('../graphql/contingent/GetStudentById.gql');
const GET_CURRENT_SEMESTER = require('../graphql/contingent/GetCurrentSemester.gql');

export default {

    state: {
        contingentSelectItem: null,
        contingentItems: [],
        contingentTotalItems: 0,
        contingentLoaderItems: false,
        contingentOptions: {},
        contingentContribution: {},
    },
    getters: {
        getContingentContribution(state: any) {
            return state.contingentContribution;
        },
        getContingentOptions(state: any) {
            return state.contingentOptions;
        },
        getContingentItems(state: any) {
            return state.contingentItems;
        },
        getContingentSelectItem(state: any) {
            return state.contingentSelectItem;
        },
        getContingentTotalItems(state: any) {
            return state.contingentTotalItems;
        },
        getContingentLoaderItems(state: any) {
            return state.contingentLoaderItems;
        },
        getContingentTablePage(state: any) {
            return state.contingentTablePage;
        },
        getContingentTableItemsPerPage(state: any) {
            return state.contingentTableItemsPerPage;
        },
    },
    mutations: {
        setContingentContribution(state: any, payload: any) {
            state.contingentContribution = payload;
        },
        setContingentItems(state: any, payload: any) {
            state.contingentItems = payload;
        },
        setContingentSelectItem(state: any, payload: any) {
            state.contingentSelectItem = payload;
        },
        setContingentTotalItems(state: any, payload: any) {
            state.contingentTotalItems = payload;
        },
        setContingentLoaderItems(state: any, payload: any) {
            state.contingentLoaderItems = payload;
        },
        setContingentTablePage(state: any, payload: any) {
            state.contingentTablePage = payload;
        },
        setContingentTableItemsPerPage(state: any, payload: any) {
            state.contingentTableItemsPerPage = payload;
        },
        updateItemInArrayItems(state: any, payload: any) {
            for (const item of state.contingentItems) {
                if (item.id === payload.id) {
                    item.profile = payload.profile;
                    break;
                }
            }
        },
    },
    actions: {
        async defaultLoadContingent({commit}: any, payload: any) {
            try {
                commit('setContingentLoaderItems', true);
                HTTP.defaults.headers.common[AUTH_CONST] = 'Token ' + localStorage.getItem('user-token');
                HTTP.defaults.headers.common[CONTENT_TYPE_CONST] = 'application/json';
                const filter = StudentFilter.fromDto({
                    fullname: payload.fullname,
                    studentNumber: payload.studentNumber,
                    facultyIds: payload.facultyIds,
                    groupIds: payload.groupIds,
                    courses: payload.courses,
                    hasStipendia: payload.hasStipendia,
                    isMember: payload.isMember,
                    educationForms: payload.educationForms,
                    educationConditions: payload.educationConditions,
                    statuses: payload.status,
                    search: payload.search,
                });
                const pagination = Pagination.fromDto({
                    page: payload.page,
                    limit: payload.limit,
                });
                await HTTP.post('/graphql', {
                    query: print(PRE_LOAD_CONTINGENT),
                    variables: {
                        filter,
                        pagination,
                    },
                }).then((response) => {
                    commit('setContingentItems', response.data.data.student.students.getList.list);
                    commit('setContingentTotalItems', response.data.data.student.students.getList.total);
                    commit('setContingentLoaderItems', false);
                    return response;
                });
            } catch (e) {
                commit('setContingentLoaderItems', false);
            }
        },
        async getCurrentSemester({commit}: any) {
            try {
                commit('setContingentLoaderItems', true);
                HTTP.defaults.headers.common[AUTH_CONST] = 'Token ' + localStorage.getItem('user-token');
                HTTP.defaults.headers.common[CONTENT_TYPE_CONST] = 'application/json';
                await HTTP.post('/graphql', {
                    query: print(GET_CURRENT_SEMESTER),
                }).then((response) => {
                    const current = response.data.data.student.semesters.getCurrent;
                    commit('setContingentContribution', {payRate: current.payRate, payRateSpo: current.payRateSpo});
                });
            } catch (e) {
                commit('setContingentLoaderItems', false);
            }
        },
        async makeMember({commit}: any, payload: any) {
            try {
                await HTTP.post('/v/0.2/student/student/' + payload.item.id + '/make_member/', {
                    date_from: payload.memberDate,
                }).then((response) => {
                    payload.item.isMember = response.data.status === 'Success';
                });
            } catch (e) {
            }
        },

        async addPayment({commit}: any, payload: any) {
            try {
                for (const debt of payload.debts) {
                    if (debt.item.payRate !== null) {
                        if (debt.type === 'SEMESTER') {
                            await HTTP.post('/v/0.2/student/payments/',
                                {
                                    payment: debt.item.payRate,
                                    by_cash: payload.by_cash,
                                    student: payload.id,
                                    semester: debt.item.id,
                                    stipendia: null,
                                }, {
                                    headers: {
                                        Authorization: 'Token ' + localStorage.getItem('user-token'),
                                    },
                                });
                        } else {
                            HTTP.defaults.headers.common[AUTH_CONST] = 'Token ' + localStorage.getItem('user-token');
                            HTTP.defaults.headers.common[CONTENT_TYPE_CONST] = 'application/json';
                            await HTTP.post('/graphql', {
                                query: print(GET_STATUS_UNION_CARD),
                                variables: {
                                    id: debt.item.id,
                                },
                            }).then((response) => {
                                const unionCard = response.data.data.student.unionCard.getById;
                                HTTP.post('/graphql', {
                                    query: print(SET_STATUS_UNION_CARD),
                                    variables: {
                                        id: unionCard.id,
                                        data: {
                                            issueDate: unionCard.issueDate,
                                            payed: true,
                                            status: unionCard.status,
                                            issueStatement: unionCard.issueStatement,
                                        },
                                    },
                                }).then(() => {
                                    },
                                );
                            });
                        }
                    }
                }
            } catch (error) {
            }
        },

        async updateContingentItemDebts({commit}: any, payload: any) {
            try {
                HTTP.defaults.headers.common[AUTH_CONST] = 'Token ' + localStorage.getItem('user-token');
                HTTP.defaults.headers.common[CONTENT_TYPE_CONST] = 'application/json';
                const response = await HTTP.post('/graphql', {
                    query: print(GET_STUDENT_DEBTS),
                    variables: {
                        studentId: payload.id,
                    },
                });
                const debts = response.data.data.student.students.getById.debts;
                payload.debts = debts;
                return debts.length;
            } catch (e) {
            }
        },
        async updateContingentSelectItem({commit}: any, payload: any) {
            try {
                HTTP.defaults.headers.common[AUTH_CONST] = 'Token ' + localStorage.getItem('user-token');
                HTTP.defaults.headers.common[CONTENT_TYPE_CONST] = 'application/json';
                await HTTP.post('/graphql', {
                    query: print(GET_STUDENT_BY_ID),
                    variables: {
                        id: payload.id,
                    },
                }).then((response) => {
                    const temp = response.data.data.student.students.getById;
                    commit('setContingentSelectItem', temp);
                    commit('updateItemInArrayItems', temp);
                });
            } catch (e) {
            }
        },
    },
};
