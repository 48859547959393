import {Semester} from '@/model/dto/SemesterDto';
import moment from 'moment';

interface StipendiaDto {
    id?: string;
    size?: string;
    optionalPeriodStart?: string;
    optionalPeriodEnd?: string;
    name?: string;
    commentary?: string;
    automatedPayment?: boolean;
    requiredOptionalPeriod?: boolean;
    semester?: Semester;
}

export class Stipendia {
    public static fromDto(
        dto: StipendiaDto,
    ): Stipendia {
        return new Stipendia(
            dto.id,
            dto.size,
            dto.optionalPeriodStart,
            dto.optionalPeriodEnd,
            dto.name,
            dto.commentary,
            dto.automatedPayment,
            dto.requiredOptionalPeriod,
            dto.semester,
        );
    }

    public id?: string;
    public size?: string;
    public optionalPeriodStart?: string;
    public optionalPeriodEnd?: string;
    public name?: string;
    public commentary?: string;
    public automatedPayment?: boolean;
    public requiredOptionalPeriod?: boolean;
    public semester?: Semester;


    constructor(
        id: string | undefined,
        size: string | undefined,
        optionalPeriodStart: string | undefined,
        optionalPeriodEnd: string | undefined,
        name: string | undefined,
        commentary: string | undefined,
        automatedPayment: boolean | undefined,
        requiredOptionalPeriod: boolean | undefined,
        semester: Semester | undefined,
    ) {
        this.id = id;
        this.size = size;
        this.optionalPeriodStart = optionalPeriodStart;
        this.optionalPeriodEnd = optionalPeriodEnd;
        this.name = name;
        this.commentary = commentary;
        this.automatedPayment = automatedPayment;
        this.requiredOptionalPeriod = requiredOptionalPeriod;
        this.semester = semester;
    }

    public getStartDate() {
        if (this.optionalPeriodStart === null) {
            return '---';
        }
        return moment(this.optionalPeriodStart).locale('ru').format('L');
    }

    public getEndDate() {
        if (this.optionalPeriodEnd === null) {
            return '---';
        }
        return moment(this.optionalPeriodEnd).locale('ru').format('L');
    }
}
