<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
	<div>
		<v-navigation-drawer
				v-model="showDrawer"
				app
				right>
			<div v-if="filterDrawer">
				<v-card flat>
					<v-app-bar
							color="primary"
							dark
							elevation="0">
						<v-btn @click="closeDrawer()" class="ma-1" small icon>
							<v-icon>mdi-close</v-icon>
						</v-btn>
						<p class="mt-10 mb-10">Фильтр</p>
					</v-app-bar>
					<v-divider></v-divider>
					<v-list>
						<v-list-item>
							<v-autocomplete
									v-model="selectFacultyList"
									:items="facultyList"
									item-text='shortname'
									item-value='id'
									label="Факультет"
									single-line
									clearable
									:disabled="loadingFacultyStatus"
									:loading="loadingFacultyStatus"
									@change="getGroupsOfFaculty">
							</v-autocomplete>
						</v-list-item>

						<v-list-item
								v-show="selectFacultyList != null">
							<v-autocomplete
									v-model="selectGroupList"
									label="Группа"
									:items="groupList"
									item-text="name"
									item-value="id"
									single-line
									clearable
									:disabled="loadingGroupStatus"
									:loading="loadingGroupStatus"
							>

							</v-autocomplete>
						</v-list-item>

						<v-list-item>
							<v-select
									v-model="filterTemp"
									:items="unionCardStatus"
									item-text="text"
									item-value="value"
									label="Статус"
									multiple
							>
								<template v-slot:selection="{ item, index }">
										<span v-if="index === 0">
											<span>{{ item.text }}</span>
										</span>
									<span
											v-if="index === 1"
											class="grey--text caption"
									> (+ ещё {{ filterTemp.length - 1 }})</span>
								</template>
							</v-select>
						</v-list-item>


						<v-list-item>
							<v-btn block color="primary"
										 @click="setFilter"
										 :loading="loadingItems">Поиск
							</v-btn>
						</v-list-item>
						<v-list-item>
							<v-btn block text color="primary" @click="resetFilter">Сбросить</v-btn>
						</v-list-item>
					</v-list>
				</v-card>
			</div>
			<div v-if="profileDrawer">
				<cont-profile-drawer :selectItem="profileItem"
														 @closeProfile="closeDrawer"></cont-profile-drawer>
			</div>
		</v-navigation-drawer>

		<v-card class="ma-xl-1 ma-sm-2 ma-xs-2">
			<v-card-title>Профсоюзный билет</v-card-title>
			<v-data-table
					:headers="headers"
					:items="items"
					:loading=loadingItems
					item-key="id"
					loading-text="Загрузка ..."
					fixed-header
					:mobile-breakpoint=321
					:server-items-length="totalItems"
					:items-per-page="15"
					:options.sync="options"
					:footer-props="{
						itemsPerPageOptions: [15, 30, 50, 100],
						firstIcon: 'mdi-arrow-collapse-left',
						lastIcon: 'mdi-arrow-collapse-right',
						prevIcon: 'mdi-arrow-left',
						nextIcon: 'mdi-arrow-right'
					}"
					class="ma-2 ma-sm-0 ma-xl-0 ma-md-0 ma-lg-0"
			>
				<template v-slot:top>
					<v-row>
						<v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
							<v-text-field
									v-model="searchString"
									@keydown.enter="searchUnionCard(searchString)"
									append-icon="mdi-magnify"
									class="ma-1 pa-2"
									label="Поиск"
									dense
									clearable
							></v-text-field>
						</v-col>
						<v-col v-if="!$vuetify.breakpoint.smAndDown" cols="12" md="4" lg="6" xl="6">
							<v-spacer></v-spacer>
						</v-col>
						<v-col class="text-right" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
							<v-btn class="mr-3" outlined @click="openFilterDrawer">
								<v-icon>mdi-filter-variant</v-icon>
								Фильтр
							</v-btn>
						</v-col>
					</v-row>
				</template>

				<template v-slot:item.profile.fullname="{item}">
					<div class="d-flex expander">
						<div class="text-name" @click="openProfileDrawer(item)">
							{{item.profile.fullname}}
						</div>
					</div>
				</template>

				<template v-slot:item.profile.student.group.faculty.shortname="{item}">
					<div class="d-flex expander" @click="openProfileDrawer(item)">
						<div class="text-other" v-if="item.profile.student != null">
							{{item.profile.student.group.faculty.shortname}}
						</div>
					</div>
				</template>

				<template v-slot:item.profile.student.group.name="{item}">
					<div
							class="d-flex expander"
							@click="openProfileDrawer(item)">
						<div class="text-other"
								 v-if="item.profile.student != null">
							{{item.profile.student.group.name}}
						</div>
					</div>
				</template>

				<template v-slot:item.profile.birthday="{item}">
					<div class="d-flex expander">
						<div class="text-other" @click="openProfileDrawer(item)">
							{{getBirthdayDate(item.profile.birthday)}}
						</div>
					</div>
				</template>

				<template v-slot:item.profile.student.memberFrom="{item}">
					<div @click="openProfileDrawer(item)" class="d-flex expander">
						<div class="text-other">
							{{getJoinMemberDate(item.profile.student)}}
						</div>
					</div>
				</template>

				<template v-slot:item.status=" {item}">
					<v-btn
							text
							:color=getStatusColor(item.status)
							@click="openStatusDialog(item)"
					>{{getStatusText(item.status)}}
					</v-btn>
				</template>


			</v-data-table>
		</v-card>

		<v-dialog
				v-model="statusDialog"
				max-width="600px"
				:fullscreen="$vuetify.breakpoint.xsOnly"
		>
			<v-card>
				<v-card-title>Подтверждение</v-card-title>
				<v-divider></v-divider>
				<v-card-text>
					<div class="pl-1 pt-2">
						<v-row>
							<p class="subtitle-1 font-weight-bold pl-2" v-if="selectUnionCard.profile.sex === 'м'">Студент: </p>
							<p class="subtitle-1 font-weight-bold pl-2" v-else>Студентка: </p>
							<p class="subtitle-1">{{selectUnionCard.profile.fullname}}</p>
						</v-row>
						<v-row>
							<p class="subtitle-1 font-weight-bold pl-2 pr-2">Текущий статус:
								{{getStatusText(selectUnionCard.status)}}</p>
						</v-row>
						<v-row>
							<p class="subtitle-1 font-weight-bold pl-2 pr-2">Изменить статус на:
								{{getStatusText(getNextStatus(selectUnionCard.status))}}</p>
						</v-row>
					</div>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-btn color="primary" text @click="statusDialog=false">Отменить</v-btn>
					<v-spacer></v-spacer>
					<v-btn color="primary" @click="setStatusUnionCard(selectUnionCard)">Подтвердить</v-btn>
					<v-spacer v-if="$vuetify.breakpoint.xsOnly"></v-spacer>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog
				v-model="statementDialog"
				hide-overlay
				:fullscreen="$vuetify.breakpoint.xsOnly"
		>
			<v-card>
				<v-card-title>
					<v-btn
							@click="statementDialog=false"
							icon>
						<v-icon>mdi-close</v-icon>
					</v-btn>
					Заявление на вступление
				</v-card-title>
				<v-card-text>
					<v-img
							class="grey lighten-2"
							:src="selectStatement">
						<template v-slot:placeholder>
							<v-row
									class="fill-height ma-0"
									align="center"
									justify="center"
							>
								<v-progress-circular indeterminate color="primary dark-5"></v-progress-circular>
							</v-row>
						</template>
					</v-img>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
    import moment from 'moment';
    import {IN_WORK, PENDING, READY, ISSUED, CANCELED, CLOSED} from '../../../../share/const-strings';

    import ProfileDrawer from '../../../share/ProfileDrawer.vue';

    export default {
        name: 'UnionCard',
        data() {
            return {
                headers: [
                    {
                        text: 'Статус',
                        sortable: false,
                        align: 'center',
                        value: 'status',
                    },
                    {
                        text: 'ФИО',
                        align: 'left',
                        sortable: true,
                        value: 'profile.fullname',
                    },
                    {
                        text: 'Факультет',
                        sortable: false,
                        align: 'center',
                        value: 'profile.student.group.faculty.shortname',
                    },
                    {
                        text: 'Группа',
                        sortable: false,
                        align: 'center',
                        value: 'profile.student.group.name',
                    },
                    {
                        text: 'Дата рождения',
                        sortable: false,
                        align: 'center',
                        value: 'profile.birthday',
                    },
                    {
                        text: 'Год вступления',
                        sortable: false,
                        align: 'center',
                        value: 'profile.student.memberFrom',
                    },
                ],
                options: {},
                unionCardStatus: [
                    PENDING,
                    IN_WORK,
                    READY,
                    ISSUED,
                    CANCELED,
                    CLOSED,
                ],
                loadingFacultyStatus: false,
                loadingGroupStatus: false,
                statusDialog: false,
                statementDialog: false,
                selectUnionCard: {
                    profile: {
                        fullname: '',
                    },
                    status: '',
                },
                profileItem: {},
                selectStatement: '',
                currentStatus: '',
                nextStatus: '',
                searchString: '',
                groupList: [],
                selectFacultyList: null,
                selectGroupList: null,
                filterTemp: [],
                filterDrawer: false,
                profileDrawer: false,
                showDrawer: false,
            };
        },
        methods: {
            openStatusDialog(item) {
                const currentStatus = item.status;
                if (currentStatus !== CLOSED.value
                    && currentStatus !== CANCELED.value
                    && currentStatus !== ISSUED.value) {
                    this.selectUnionCard = item;
                    this.statusDialog = true;
                }
            },
            getStatusColor(status) {
                switch (status) {
                    case PENDING.value:
                        return PENDING.color;
                    case IN_WORK.value:
                        return IN_WORK.color;
                    case READY.value:
                        return READY.color;
                    case ISSUED.value:
                        return ISSUED.color;
                    case CANCELED.value:
                        return CANCELED.color;
                    case CLOSED.value:
                        return CLOSED.color;
                    default:
                        return 'grey';
                }
            },
            getStatusText(status) {
                switch (status) {
                    case PENDING.value:
                        return PENDING.text;
                    case IN_WORK.value:
                        return IN_WORK.text;
                    case READY.value:
                        return READY.text;
                    case ISSUED.value:
                        return ISSUED.text;
                    case CANCELED.value:
                        return CANCELED.text;
                    case CLOSED.value:
                        return CLOSED.text;
                    default:
                        return 'Неизвестен';
                }
            },
            getNextStatus(status) {
                switch (status) {
                    case PENDING.value:
                        return IN_WORK.value;
                    case IN_WORK.value:
                        return READY.value;
                    case READY.value:
                        return ISSUED.value;
                    case ISSUED.value:
                        return null;
                }
            },
            getGroupsOfFaculty() {
                this.groupList = [];
                this.selectGroupList = null;
                let facultyId = 0;
                if (this.selectFacultyList != null) {
                    this.loadingGroupStatus = true;
                    facultyId = this.selectFacultyList;
                    this.$store.dispatch('getGroupList', {faculty_id: facultyId}).then(() => {
                        this.groupList = this.$store.getters.getGroupList;
                        this.loadingGroupStatus = false;
                    });
                } else {
                    this.groupList = [];
                }
            },
            setStatusUnionCard(item) {
                const nextStatus = this.getNextStatus(item.status);
                if (nextStatus != null) {
                    this.$store.dispatch('setStatusUnionCard', {item, nextStatus})
                        .then((response) => {
                            this.statusDialog = false;
                        });
                }
            },
            searchUnionCard(searchString) {
                this.selectFacultyList = null;
                this.selectGroupList = null;
                const facultyId = this.getAvailableFacultyId();
                if (searchString.length > 0) {
                    const searchStr = searchString.trim();
                    this.$store.dispatch('defaultLoadUnionCard', {
                        fullname: searchStr,
                        facultyId,
                        page: 0,
                        limit: this.options.itemsPerPage,
                    }).then(() => {
                        this.options.page = 1;
                    });
                } else {
                    this.$store.dispatch('defaultLoadUnionCard', {
                        status: this.filterTemp,
                        facultyId,
                        page: 0,
                        limit: this.options.itemsPerPage,
                    }).then(() => {
                            this.options.page = 1;
                        },
                    );
                }
            },
            setFilter() {
                const facultyId = this.getAvailableFacultyId();

                this.$store.dispatch('defaultLoadUnionCard', {
                    fullname: null,
                    statuses: this.filterTemp,
                    facultyId,
                    groupId: this.selectGroupList,
                    page: 0,
                    limit: this.options.itemsPerPage,
                }).then(() => {
                    this.options.page = 1;
                });
            },
            resetFilter() {
                this.filterTemp = [];
                this.selectFacultyList = null;
                this.selectGroupList = null;
            },
            getBirthdayDate(birthdayDate) {
                return moment(birthdayDate).locale('ru').format('L');
            },
            getJoinMemberDate(joinMemberDate) {
                if (joinMemberDate == null || joinMemberDate.memberFrom == null) {
                    return 'Отсутствует';
                }
                if (joinMemberDate.memberFrom != null) {
                    return joinMemberDate.memberFrom.substr(0, 4);
                }
            },
            getAvailableFacultyId() {
                const facultyList = this.$store.getters.getFacultyList;
                const facultyId = [];
                if (!this.$store.getters.getIsStaff || !this.$store.getters.getIsSuperUser) {
                    if (facultyList.length < 2) {
                        facultyId.push(this.$store.getters.getMyFaculty.id);
                        return facultyId;
                    } else if (facultyList.length === 3) {
                        if (this.selectFacultyList == null) {
                            for (const faculty of facultyList) {
                                facultyId.push(faculty.id);
                            }
                            return facultyId;
                        } else {
                            return this.selectFacultyList;
                        }
                    }
                } else {
                    return this.$store.getters.getSelectFaculties;
                }
            },
            openProfileDrawer(item) {
                if (item.profile.student == null) {
                    item.profile.student = {
                        group: null,
                    };
                }

                // TODO Добавить информацию о долгах
                this.profileItem = {
                    id: item.profile.id,
                    photo: item.profile.mainPhoto,
                    sex: item.profile.sex,
                    files: item.profile.files,
                    fullname: item.profile.fullname,
                    group: item.profile.student.group,
                    studentNumber: item.profile.student.studentNumber,
                    phone: item.profile.phone,
                    email: item.profile.email,
                };

                this.filterDrawer = false;
                this.profileDrawer = true;
                this.showDrawer = true;
            },
            openFilterDrawer() {
                this.profileDrawer = false;
                this.filterDrawer = true;
                this.showDrawer = true;
            },
            closeDrawer() {
                this.showDrawer = false;
            },
        },
        computed: {
            items() {
                return this.$store.getters.getUnionCardItems;
            },
            totalItems() {
                return this.$store.getters.getUnionCardTotalItems;
            },
            loadingItems() {
                return this.$store.getters.getUnionCardLoaderItems;
            },
            facultyList() {
                return this.$store.getters.getFacultyList;
            },
        },
        watch: {
            options: {
                handler() {
                    if (this.profileDrawer) {
                        this.closeDrawer();
                    }
                    let fullname = null;
                    if (this.searchString != null) {
                        fullname = this.searchString.trim();
                    }
                    const facultyId = this.getAvailableFacultyId();
                    this.$store.dispatch('defaultLoadUnionCard', {
                        fullname,
                        statuses: this.filterTemp,
                        facultyId,
                        groupId: this.selectGroupList,
                        page: this.options.page - 1,
                        limit: this.options.itemsPerPage,
                    });
                },
            },
        },
        component: {
            contProfileDrawer: ProfileDrawer,
        },
    };
</script>

<style scoped>
	.expander {
		height: 100%;
	}

	.text-name {
		display: block;
		height: min-content;
		margin-top: auto;
		margin-bottom: auto;
	}

	.text-other {
		display: block;
		height: min-content;
		margin: auto;
	}

</style>