export const INCORRECT_DATA: string = 'Wrong credentials. Try another login/password';
export const INCORRECT_DATA_RU: string = 'Неправильные данные. Проверьте логин/пароль';
export const STATUS_ACTIVE = {text: 'Учится', value: 'active'};
export const STATUS_GRADUATE = {text: 'Окончил', value: 'graduate'};
export const STATUS_ACADEM = {text: 'Академический отпуск', value: 'academ'};
export const STATUS_WASTED = {text: 'Отчислен', value: 'wasted'};
export const PENDING: object = {text: 'Ожидание', value: 'PENDING', color: 'red'};
export const IN_WORK: object = {text: 'В работе', value: 'IN_WORK', color: 'primary'};
export const READY: object = {text: 'Готов', value: 'READY', color: 'success'};
export const ISSUED: object = {text: 'Выдан', value: 'ISSUED', color: 'grey'};
export const CANCELED: object = {text: 'Отменён', value: 'CANCELED', color: 'grey'};
export const CLOSED: object = {text: 'Закрыт', value: 'CLOSED', color: 'grey'};
