import Vue from 'vue';
import Vuex from 'vuex';
import auth from './auth';
import profile from './profile';
import contingent from './contingent';
import unionCard from './union_card';
import universityStructure from './university_structure';
import loadStatementDialog from './components/load_statement_dialog';
import filterDrawer from './components/contingent/filterDrawer';
import alertSnackbar from './components/alert_snackbar';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        profile,
        contingent,
        unionCard,
        universityStructure,
        loadStatementDialog,
        filterDrawer,
        alertSnackbar,
    },
    state: {},
    mutations: {},
    actions: {},
});
