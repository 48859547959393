interface StudentFilterDto {
    fullname?: string;
    email?: string;
    phone?: string;
    sex?: string;
    username?: string;
    groupIds?: [string];
    specialityIds?: [string];
    facultyIds?: [string];
    studentNumber?: string;
    externalId?: string;
    hasStipendia?: boolean;
    isMember?: boolean;
    campusIds?: [string];
    educationConditions?: [string];
    educationForms?: [string];
    statuses?: [string];
    courses?: [string];
    userIds?: [string];
    profileIds?: [string];
    search?: string;
}

export class StudentFilter {
    public static fromDto(
        dto: StudentFilterDto,
    ): StudentFilter {
        return new StudentFilter(
            dto.fullname,
            dto.email,
            dto.phone,
            dto.sex,
            dto.username,
            dto.groupIds,
            dto.specialityIds,
            dto.facultyIds,
            dto.studentNumber,
            dto.externalId,
            dto.hasStipendia,
            dto.isMember,
            dto.campusIds,
            dto.educationConditions,
            dto.educationForms,
            dto.statuses,
            dto.courses,
            dto.userIds,
            dto.profileIds,
            dto.search,
        );
    }

    public fullname?: string;
    public email?: string;
    public phone?: string;
    public sex?: string;
    public username?: string;
    public groupIds?: [string];
    public specialityIds?: [string];
    public facultyIds?: [string];
    public studentNumber?: string;
    public externalId?: string;
    public hasStipendia?: boolean;
    public isMember?: boolean;
    public campusIds?: [string];
    public educationConditions?: [string];
    public educationForms?: [string];
    public statuses?: [string];
    public courses?: [string];
    public userIds?: [string];
    public profileIds?: [string];
    public search?: string;

    constructor(
        fullname?: string,
        email?: string,
        phone?: string,
        sex?: string,
        username?: string,
        groupIds?: [string],
        specialityIds?: [string],
        facultyIds?: [string],
        studentNumber?: string,
        externalId?: string,
        hasStipendia?: boolean,
        isMember?: boolean,
        campusIds?: [string],
        educationConditions?: [string],
        educationForms?: [string],
        statuses?: [string],
        courses?: [string],
        userIds?: [string],
        profileIds?: [string],
        search?: string,
    ) {
        this.fullname = fullname;
        this.email = email;
        this.phone = phone;
        this.sex = sex;
        this.username = username;
        this.groupIds = groupIds;
        this.specialityIds = specialityIds;
        this.facultyIds = facultyIds;
        this.studentNumber = studentNumber;
        this.externalId = externalId;
        this.hasStipendia = hasStipendia;
        this.isMember = isMember;
        this.campusIds = campusIds;
        this.educationConditions = educationConditions;
        this.educationForms = educationForms;
        this.statuses = statuses;
        this.courses = courses;
        this.userIds = userIds;
        this.profileIds = profileIds;
        this.search = search;
    }
}
