<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <v-card class="fill-height" flat>
            <v-app-bar
                    color="primary"
                    dark
                    elevation="0">
                <v-btn @click="closeDrawer()" class="ma-1" small icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <p class="mt-10 mb-10">Фильтр</p>
            </v-app-bar>
            <v-divider></v-divider>
            <v-list>
                <v-list-item v-if="myUserInfo.getCountAvailableFaculties() !== 1">
                    <v-autocomplete
                            v-model="selectFacultyList"
                            :loading="loadingFacultyStatus"
                            :disabled="loadingFacultyStatus"
                            :items="facultyList"
                            item-text='shortname'
                            item-value='id'
                            label="Факультет"
                            clearable
                            @change="getGroupsOfFaculty">
                        >
                    </v-autocomplete>
                </v-list-item>
                <v-list-item v-else>
                    <v-autocomplete
                            v-model="selectFacultyList"
                            :disabled="true"
                            :items="facultyList"
                            item-text='shortname'
                            item-value='id'
                            label="Факультет">¬
                    </v-autocomplete>
                </v-list-item>

                <v-list-item
                        v-if="selectFacultyList != null ">
                    <v-autocomplete
                            v-model="selectGroupList"
                            :disabled="loadingGroupStatus"
                            :loading="loadingGroupStatus"
                            :items="groupList"
                            item-text="name"
                            item-value="id"
                            clearable
                            label="Группа">
                    </v-autocomplete>
                </v-list-item>

                <v-list-item>
                    <v-select
                            v-model="selectCourses"
                            label="Курс"
                            :items="filterCourse"
                            item-text="text"
                            item-value="value"
                            clearable
                            multiple
                            @change="getGroupOfCourse"
                    >
                        <template v-if="selectCourses.length === 0 || selectCourses.length === filterCourse.length"
                                  v-slot:selection="{ item, index }">
                            <span v-if="index === 0">Все курсы</span>
                        </template>
                    </v-select>
                </v-list-item>

                <v-list-item>
                    <v-select
                            v-model="selectStipends"
                            label="Есть стипендия?"
                            :items="filterStipends"
                            item-text="text"
                            item-value="value"
                            clearable>
                    </v-select>
                </v-list-item>

                <v-list-item>
                    <v-select
                            v-model="selectConditionEducation"
                            label="Условия обучения"
                            :items="filterConditionEducation"
                            item-text="text"
                            item-value="value"
                            multiple
                            clearable>
                        <template
                                v-if="selectConditionEducation.length === 0 || selectConditionEducation.length === filterConditionEducation.length"
                                v-slot:selection="{item, index }">
                            <span v-if="index === 0">Все условия</span>
                        </template>
                    </v-select>
                </v-list-item>

                <v-list-item>
                    <v-select
                            v-model="selectFormEducation"
                            label="Форма обучения"
                            :items="filterFormEducation"
                            item-text="text"
                            item-value="value"
                            multiple
                            clearable>
                        <template
                                v-if="selectFormEducation.length === 0 || selectFormEducation.length === filterFormEducation.length"
                                v-slot:selection="{ item, index }">
                            <span v-if="index === 0">Все формы</span>
                        </template>
                    </v-select>
                </v-list-item>

                <v-list-item>
                    <v-select
                            v-model="selectStatus"
                            label="Статус"
                            :items="filterStatus"
                            item-text="text"
                            item-value="value"
                            multiple
                            clearable>
                        <template v-if="selectStatus.length === filterStatus.length || selectStatus.length === 0"
                                  v-slot:selection="{ item, index }">
                            <div v-if="index === 0">Все статусы</div>
                        </template>
                    </v-select>
                </v-list-item>

                <v-list-item>
                    <v-select
                            v-model="selectMember"
                            label="Член профсоюза?"
                            :items="filterMember"
                            item-text="text"
                            item-value="value"
                            clearable>
                    </v-select>
                </v-list-item>

                <v-list-item>
                    <v-btn :loading=loaderButton
                           block
                           color="primary"
                           @click="setFilters">Поиск
                    </v-btn>
                </v-list-item>

                <v-list-item>
                    <v-btn block text color="primary" @click="resetFilters()">Сбросить</v-btn>
                </v-list-item>
            </v-list>
        </v-card>

    </div>
</template>

<script>

    import {STATUS_ACADEM, STATUS_ACTIVE, STATUS_GRADUATE, STATUS_WASTED} from '@/share/const-strings';
    import {User} from '@/model/dto/User';

    export default {
        name: 'FilterDrawer',
        data() {
            return {
                myUserInfo: new User(),
                groupList: [],
                selectGroupList: null,
                selectCourses: [],
                selectStipends: null,
                selectStatus: ['active'],
                selectFormEducation: [],
                selectConditionEducation: [],
                selectMember: null,
                filterCourse: [
                    {text: '1 курс', value: 1},
                    {text: '2 курс', value: 2},
                    {text: '3 курс', value: 3},
                    {text: '4 курс', value: 4},
                    {text: '5 курс', value: 5},
                    {text: '6 курс', value: 6},
                ],
                filterStipends: [
                    {text: 'Да', value: true},
                    {text: 'Нет', value: false},
                    {text: 'Все', value: null},
                ],
                filterStatus: [
                    STATUS_ACTIVE,
                    STATUS_ACADEM,
                    STATUS_WASTED,
                    STATUS_GRADUATE,
                ],
                filterFormEducation: [
                    {text: 'Очная', value: 'in'},
                    {text: 'Заочная', value: 'ex'},
                    {text: 'Очно-заочная', value: 'ie'}],
                filterConditionEducation: [
                    {text: 'Бюджет', value: 'bu'},
                    {text: 'Коммерция', value: 'co'},
                    {text: 'Целевой набор', value: 'cn'},
                    {text: 'Особое право', value: 'op'},
                    {text: 'Министерство образования', value: 'mo'},
                ],
                filterMember: [
                    {text: 'Да', value: true},
                    {text: 'Нет', value: false},
                    {text: 'Все', value: null},
                ],
                loadingFacultyStatus: false,
                loadingGroupStatus: false,
            };
        },
        methods: {
            closeDrawer() {
                this.$emit('closeFilter');
            },

            getGroupsOfFaculty() {
                this.groupList = [];
                this.selectGroupList = null;
                let facultyId = 0;
                if (this.selectFacultyList !== null) {
                    this.loadingGroupStatus = true;
                    facultyId = this.selectFacultyList;
                    this.$store.dispatch('getGroupList', {faculty_id: facultyId}).then(() => {
                        if (this.selectCourses.length !== 0) {
                            this.selectCourses.sort((a, b) => {
                                return a - b;
                            });
                            for (const course of this.selectCourses) {
                                for (const group of this.$store.getters.getGroupList) {
                                    if (course === group.course) {
                                        this.groupList.push(group);
                                    }
                                }
                            }
                        } else {
                            this.groupList = this.$store.getters.getGroupList;
                        }
                        this.loadingGroupStatus = false;
                    });
                } else {
                    this.groupList = [];
                }
            },

            getGroupOfCourse() {
                this.groupList = [];
                this.selectGroupList = null;
                if (this.selectFacultyList !== null) {
                    this.loadingGroupStatus = true;
                    if (this.selectCourses.length !== 0) {
                        this.selectCourses.sort((a, b) => {
                            return a - b;
                        });
                        for (const course of this.selectCourses) {
                            for (const group of this.$store.getters.getGroupList) {
                                if (course === group.course) {
                                    this.groupList.push(group);
                                }
                            }
                        }
                    } else {
                        this.groupList = this.$store.getters.getGroupList;
                    }
                    this.loadingGroupStatus = false;
                } else {
                    this.groupList = [];
                }
            },

            setFilters() {
                this.$store.commit('setSelectFaculties', this.selectFacultyList);
                this.$store.commit('setSelectGroups', this.selectGroupList);
                this.$store.commit('setSelectCourses', this.selectCourses);
                this.$store.commit('setSelectStipends', this.selectStipends);
                this.$store.commit('setSelectStatuses', this.selectStatus);
                this.$store.commit('setSelectFormEducations', this.selectFormEducation);
                this.$store.commit('setSelectConditionEducations', this.selectConditionEducation);
                this.$store.commit('setSelectMembers', this.selectMember);
                this.$emit('setFilter');
            },

            resetFilters() {
                if (this.myUserInfo.getCountAvailableFaculties() !== 1) {
                    this.selectFacultyList = null;
                }
                this.selectGroupList = null;
                this.selectCourses = [];
                this.selectStipends = null;
                this.selectMember = null;
                this.selectFormEducation = [];
                this.selectConditionEducation = [];
                this.selectStatus = [];
            },
        },
        computed: {
            selectFacultyList: {
                get() {
                    return this.$store.getters.getSelectFaculties;
                },
                set(val) {
                    this.$store.commit('setSelectFaculties', val);
                },
            },
            loaderButton() {
                return this.$store.getters.getContingentLoaderItems;
            },
            facultyList() {
                return this.myUserInfo.getAvailableFaculties();
            },
        },
        beforeMount() {
            this.selectGroupList = this.$store.getters.getSelectGroups;
            this.selectCourses = this.$store.getters.getSelectCourses;
            this.selectStipends = this.$store.getters.getSelectStipends;
            this.selectStatus = this.$store.getters.getSelectStatuses;
            this.selectFormEducation = this.$store.getters.getSelectFormEducations;
            this.selectConditionEducation = this.$store.getters.getSelectConditionEducations;
            this.selectMember = this.$store.getters.getSelectMembers;
            this.myUserInfo = this.$store.getters.getMyUserInfo;
            if (this.myUserInfo.getCountAvailableFaculties() === 1) {
                this.selectFacultyList = this.myUserInfo.getAvailableFaculties()[0].id;
                this.getGroupsOfFaculty();
            }
        },
    };
</script>

<style scoped>

</style>