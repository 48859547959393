interface SpecialityDto {
    name?: string;
    id: string;
}

export class Speciality {
    public static fromDto(dto: SpecialityDto): Speciality {
        return new Speciality(
            dto.name,
            dto.id,
        );
    }

    public name?: string;
    public id: string;

    constructor(
        name?: string,
        id: string = '-1',
    ) {
        this.name = name;
        this.id = id;
    }

    public getName(): string | undefined {
        return this.name;
    }
}
