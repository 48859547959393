<template>
    <div class="mt-2">
        <v-card elevation="1" outlined>
            <v-card-title class="pb-0">
                Личная
            </v-card-title>
            <v-card-text class="mt-n2">
                <v-row>
                    <v-col cols="12" xl="4" lg="4">
                        <v-text-field readonly label="Фамилия" :value="profile.getFamilyname()"/>
                    </v-col>
                    <v-col cols="12" xl="4" lg="4">
                        <v-text-field readonly label="Имя" :value="profile.getFirstname()"/>
                    </v-col>
                    <v-col cols="12" xl="4" lg="4">
                        <v-text-field readonly label="Отчество" :value="profile.getSurname()"/>
                    </v-col>
                </v-row>
                <v-row class="mt-n5">
                    <v-col>
                        <v-text-field readonly label="День рождения"
                                      :value="profile.getBirthday()"/>
                    </v-col>

                    <v-col>
                        <v-text-field readonly label="Возраст"
                                      :value="profile.getOld()"/>
                    </v-col>

                    <v-col>
                        <v-text-field readonly label="Гражданство"
                                      :value="profile.getCountry()"/>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-card elevation="1" outlined class="mt-2">
            <v-card-title class="pb-0">
                Образование
            </v-card-title>
            <v-card-text class="mt-n2">
                <v-row>
                    <v-col cols="12" xl="6" lg="6">
                        <v-text-field readonly label="Факультет"
                                      v-if="profile.getFaculty()"
                                      :value="profile.getFaculty().getName()"></v-text-field>
                    </v-col>
                    <v-col cols="12" xl="6" lg="6">
                        <v-text-field readonly label="Специальность"
                                      :value="profile.getSpeciality().getName()"></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="mt-n5">
                    <v-col>
                        <v-text-field readonly
                                      label="Группа"
                                      :value="profile.getGroup().getName()"></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field readonly
                                      label="Курс"
                                      :value="profile.getGroup().getCourse()"></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field readonly
                                      label="Форма обучения"
                                      :value="profile.getEducationForm()"></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field readonly
                                      label="Условия обучения"
                                      :value="profile.getEducationCondition()"></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="mt-n5">
                    <v-col>
                        <v-text-field readonly
                                      label="Статус"
                                      :value="profile.getStatus()"></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field readonly
                                      label="Дата начала обучения"
                                      :value="profile.getEntranceDate()"></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field readonly
                                      label="Дата окончания обучения"
                                      :value="profile.getGraduateDate()"></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-card elevation="1" outlined class="mt-2">
            <v-card-title class="pb-0">
                Профсоюз
            </v-card-title>
            <v-card-text class="mt-n2">
                <v-row>
                    <v-col>
                        <v-text-field color="primary"
                                      :value="profile.getIsMemberText()"
                                      label='Член профсоюза'
                                      readonly>
                        </v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field label='Дата вступления' readonly
                                      :value="profile.getMemberFromDate()"></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field label='Дата выхода' readonly
                                      :value="profile.getMemberEndDate()"></v-text-field>
                    </v-col>
                    <!--    <v-col>
                            <v-text-field label='Профсоюзные взносы' readonly></v-text-field></v-col>-->
                    <v-col>
                        <v-text-field color="primary"
                                      :value="profile.getIsBlackListText()"
                                      label='В чёрном списке'
                                      readonly>
                        </v-text-field>
                    </v-col>
                    <!--      <v-col>Заявление
                              <br>
                                  <v-btn color="primary" small>Открыть</v-btn>
                          </v-col>-->
                </v-row>
            </v-card-text>
        </v-card>

    </div>
</template>

<script>
    import {UserProfile} from '@/model/dto/UserProfile';

    export default {
        name: 'InformationCard',
        props: {
            profile: new UserProfile(),
        },
    };
</script>

<style scoped>

</style>