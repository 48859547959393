interface FacultyDto {
    shortname?: string;
    name?: string;
    id: string;
}

export class Faculty {
    public static fromDto(dto: FacultyDto): Faculty {
        return new Faculty(
            dto.shortname,
            dto.name,
            dto.id,
        );
    }

    public shortname: string;
    public name?: string;
    public id: string;

    constructor(
        shortname: string = '',
        name?: string,
        id: string = '-1',
    ) {
        this.shortname = shortname;
        this.name = name;
        this.id = id;
    }

    public getName(): string | undefined {
        return this.name;
    }

    public getShortName(): string | undefined {
        return this.shortname;
    }

    public getId(): string | undefined {
        return this.id;
    }
}
