interface PaginationDto {
    page?: number;
    limit?: number;
}

export class Pagination {
    public static fromDto(dto: PaginationDto): Pagination {
        return new Pagination(
            dto.page,
            dto.limit,
        );
    }

    public page?: number;
    public limit?: number;

    constructor(
        page?: number,
        limit?: number,
    ) {
        this.page = page;
        this.limit = limit;
    }
}
