export default {
    state: {
        selectFacultyList: null,
        selectGroupList: null,
        selectCourses: [],
        selectStipends: null,
        selectStatus: ['active'],
        selectFormEducation: [],
        selectConditionEducation: [],
        selectMember: null,
    },
    getters: {
        getSelectFaculties(state: any) {
            return state.selectFacultyList;
        },
        getSelectGroups(state: any) {
            return state.selectGroupList;
        },
        getSelectCourses(state: any) {
            return state.selectCourses;
        },
        getSelectStipends(state: any) {
            return state.selectStipends;
        },
        getSelectStatuses(state: any) {
            return state.selectStatus;
        },
        getSelectFormEducations(state: any) {
            return state.selectFormEducation;
        },
        getSelectConditionEducations(state: any) {
            return state.selectConditionEducation;
        },
        getSelectMembers(state: any) {
            return state.selectMember;
        },
    },
    mutations: {
        setSelectFaculties(state: any, payload: any) {
            state.selectFacultyList = payload;
        },
        setSelectGroups(state: any, payload: any) {
            state.selectGroupList = payload;
        },
        setSelectCourses(state: any, payload: any) {
            state.selectCourses = payload;
        },
        setSelectStipends(state: any, payload: any) {
            state.selectStipends = payload;
        },
        setSelectStatuses(state: any, payload: any) {
            state.selectStatus = payload;
        },
        setSelectFormEducations(state: any, payload: any) {
            state.selectFormEducation = payload;
        },
        setSelectConditionEducations(state: any, payload: any) {
            state.selectConditionEducation = payload;
        },
        setSelectMembers(state: any, payload: any) {
            state.selectMember = payload;
        },
    },
    actions: {},
};
