import {HTTP} from '@/plugins/axios';
import {print} from 'graphql';
import {User} from '@/model/dto/User';
import {Faculty} from '@/model/dto/Faculty';
import {UserProfile} from '@/model/dto/UserProfile';
import {Stipendia} from '@/model/dto/StipendiaDto';
import {Semester} from '@/model/dto/SemesterDto';
import {Group} from '@/model/dto/GroupDto';
import {Speciality} from '@/model/dto/SpecialityDto';

const AUTH_CONST: string = 'Authorization';
const CONTENT_TYPE_CONST: string = 'Content-Type';
const GET_SELF = require('../graphql/GetSelf.gql');
const GET_STUDENT_BY_ID = require('../graphql/contingent/GetStudentById.gql');
const GET_AVAILABLE_UNITS = require('../graphql/user/GetAvailableUnits.gql');
const GET_PROFILE_BY_ID = require('../graphql/profile/GetProfileById.gql');

export default {
    state: {
        myProfile: null,
        myUserInfo: new User(),
        userProfile: new UserProfile(),
    },
    getters: {
        /*==================Мой профиль=========================*/
        getMyProfile(state: any) {
            return state.myProfile;
        },
        getMyFaculty(state: any) {
            if (state.myProfile.student != null) {
                return state.myProfile.student.faculty;
            } else {
                return -1;
            }
        },
        getIsStaff(state: any) {
            return state.myProfile.user.is_staff;
        },
        getIsSuperUser(state: any) {
            return state.myProfile.user.is_superuser;
        },
        getMyUserGroups(state: any) {
            return state.myProfile.user.groups;
        },

        /*==================Мой пользователь=========================*/
        // В будущем заменит "Мой профиль"

        getMyUserInfo(state: any) {
            return state.myUserInfo;
        },

        getUserProfile(state: any) {
            return state.userProfile;
        },
    },
    mutations: {
        setMyProfile(state: any, payload: any) {
            state.myProfile = payload;
        },
        setUserProfile(state: any, payload: any) {
            const stipendias: Stipendia[] = [];
            for (const item of payload.stipendia) {
                if (item != null) {
                    if (item.semester != null) {
                        stipendias.push(Stipendia.fromDto({
                            id: item.id,
                            size: item.size,
                            optionalPeriodStart: item.optionalPeriodStart,
                            optionalPeriodEnd: item.optionalPeriodEnd,
                            name: item.type.name,
                            commentary: item.type.commentary,
                            requiredOptionalPeriod: item.type.requiredOptionalPeriod,
                            automatedPayment: item.type.automatedPayment,
                            semester: Semester.fromDto({
                                name: item.semester.name,
                                year: item.semester.year,
                                number: item.semester.number,
                            }),
                        }));
                    } else {
                        stipendias.push(Stipendia.fromDto({
                            id: item.id,
                            size: item.size,
                            optionalPeriodStart: item.optionalPeriodStart,
                            optionalPeriodEnd: item.optionalPeriodEnd,
                            name: item.type.name,
                            commentary: item.type.commentary,
                            requiredOptionalPeriod: item.type.requiredOptionalPeriod,
                            automatedPayment: item.type.automatedPayment,
                            semester: undefined,
                        }));
                    }
                }
            }
            let entranceSemester: Semester;
            if (payload.entranceSemester !== null) {
                entranceSemester = Semester.fromDto({
                    start: payload.entranceSemester.start,
                    end: payload.entranceSemester.end,
                    year: payload.entranceSemester.year,
                    name: payload.entranceSemester.name,
                    number: payload.entranceSemester.number,
                });
            } else {
                entranceSemester = new Semester(undefined, undefined, undefined, undefined, undefined);
            }
            let graduateSemester: Semester;
            if (payload.graduateSemester !== null) {
                graduateSemester = Semester.fromDto({
                    start: payload.graduateSemester.start,
                    end: payload.graduateSemester.end,
                    year: payload.graduateSemester.year,
                    name: payload.graduateSemester.name,
                    number: payload.graduateSemester.number,
                });
            } else {
                graduateSemester = new Semester(undefined, undefined, undefined, undefined, undefined);
            }
            state.userProfile = UserProfile.fromDto({
                fullname: payload.profile.fullname,
                familyname: payload.profile.familyname,
                firstname: payload.profile.firstname,
                surname: payload.profile.surname,
                country: payload.country,
                status: payload.status,
                educationForm: payload.educationForm,
                educationCondition: payload.educationCondition,
                mainPhoto: payload.profile.mainPhoto,
                sex: payload.profile.sex,
                isMember: payload.isMember,
                blacklisted: payload.blacklisted,
                memberFrom: payload.memberFrom,
                memberEnd: payload.memberEnd,
                birthday: payload.profile.birthday,
                studentNumber: payload.studentNumber,
                phone: payload.profile.phone,
                email: payload.profile.email,
                stipendia: stipendias,
                entranceSemester,
                graduateSemester,
                elUnionCard: payload.profile.elUnionCard,
                sksBarcode: payload.profile.sksBarcode,
                faculty: Faculty.fromDto({
                    name: payload.faculty.name,
                    shortname: payload.faculty.shortname,
                    id: payload.faculty.id,
                }),

                group: Group.fromDto({
                    name: payload.group.name,
                    id: payload.group.id,
                    course: payload.group.course,
                }),

                speciality: Speciality.fromDto({
                    name: payload.speciality.name,
                    id: payload.speciality.id,
                }),
            });
        },
        setAvailableFacultiesGql(state: any, payload: any) {
            const faculties: Faculty[] = [];
            for (const item of payload.faculty.getAllowed) {
                if (item != null) {
                    faculties.push(Faculty.fromDto({
                        shortname: item.shortname,
                        name: item.name,
                        id: item.id,
                    }));
                }
            }
            state.myUserInfo.availableFaculties = faculties;
        },
        setAvailableFacultiesRest(state: any, payload: any) {
            const faculties: Faculty[] = [];
            for (const item of payload) {
                if (item != null) {
                    faculties.push(Faculty.fromDto({
                        shortname: item.shortname,
                        name: item.name,
                        id: item.id,
                    }));
                }
            }
            state.myUserInfo.availableFaculties = faculties;
        },
    },
    actions: {
        /*==================Мой профиль=========================*/
        async sendQuestionnaire({commit}: any, payload: any) {
            try {
                HTTP.defaults.headers.common[AUTH_CONST] = 'Token ' + localStorage.getItem('user-token');
                const user = await HTTP.post('student/questionary/', payload);
            } catch (e) {
            }
        },
        async loadMyProfile({commit}: any) {
            HTTP.defaults.headers.common[AUTH_CONST] = 'Token ' + localStorage.getItem('user-token');
            HTTP.defaults.headers.common[CONTENT_TYPE_CONST] = 'application/json';
            await HTTP.post('/graphql', {
                query: print(GET_SELF),
            }).then((response) => {
                    commit('setMyProfile', response.data.data.core.profile.getSelf);
                },
            );

        },
        patchStudentInfo({commit}: any, payload: any) {
            HTTP.defaults.headers.common[AUTH_CONST] = 'Token ' + localStorage.getItem('user-token');
            HTTP.patch('student/my/student/', payload);
        },

        /*==================Профиль пользователя=========================*/
        async getUserProfileById({commit}: any, payload: any) {
            HTTP.defaults.headers.common[AUTH_CONST] = 'Token ' + localStorage.getItem('user-token');
            HTTP.defaults.headers.common[CONTENT_TYPE_CONST] = 'application/json';
            await HTTP.post('/graphql', {
                query: print(GET_PROFILE_BY_ID),
                variables: {
                    id: payload.id,
                },
            }).then((response) => {
                const tempProfile = response.data.data.student.students.getById;
                commit('setUserProfile', tempProfile);
            });
        },

        /*==================Мой пользователь=========================*/
        async loadAvailableFacultiesGql({commit}: any) {
            HTTP.defaults.headers.common[AUTH_CONST] = 'Token ' + localStorage.getItem('user-token');
            HTTP.defaults.headers.common[CONTENT_TYPE_CONST] = 'application/json';
            await HTTP.post('/graphql', {
                query: print(GET_AVAILABLE_UNITS),
            }).then((response) => {
                    commit('setAvailableFacultiesGql', response.data.data.student);
                },
            );
        },
    },
};
