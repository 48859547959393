<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-main class="profstyle">
        <v-container fluid fill-height>
            <v-layout align-center justify-center>
                <v-flex xs12 sm10 md8 lg6 xl4>
                    <v-card class="transparent elevation-0" @keydown.enter="goToSystem">
                        <v-img src="../../../assets/logoprofdstu.png"
                               contain
                               aspect-ratio="2.5"
                        ></v-img>
                        <v-card-title class="justify-center">
                            <p class="font-weight-regular title white--text">
                                {{timesOfDay}}
                            </p>
                        </v-card-title>
                        <v-card-text>
                            <v-form v-model="valid" ref="form" validation>
                                <v-text-field
                                        label="Имя пользователя"
                                        hint="Для входа используйте: имя пользователя, номер зачётки, телефона или эл.почта"
                                        name="login"
                                        type="text"
                                        required
                                        dark
                                        outlined
                                        v-model="username"
                                        :rules="[rules.required, rules.templateVal]"
                                >
                                </v-text-field>

                                <v-text-field
                                        label="Пароль"
                                        :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                                        :type="showPassword ? 'text': 'password'"
                                        required
                                        dark
                                        outlined
                                        v-model="password"
                                        :rules="[rules.required, rules.templateVal, rules.min]"
                                >
                                    <template v-slot:append>
                                        <v-icon color="#a2b2c6" @click="showPassword=!showPassword">
                                            {{showPassword ? 'mdi-eye-off' : 'mdi-eye'}}
                                        </v-icon>
                                    </template>
                                </v-text-field>

                                <v-alert
                                        type="error"
                                        elevation="2"
                                        outlined
                                        dark
                                        colored-border
                                        transition="scale-transition"
                                        :value="showError"
                                >
                                    {{descriptionError}}
                                </v-alert>
                            </v-form>
                            <v-row class="justify-center">
                                <v-col cols="12" xl="6" lg="6" md="6" xs="8" sm="8">
                                    <v-btn
                                            :loading="loader"
                                            @click="goToSystem"
                                            outlined
                                            dark
                                            block
                                            :large="$vuetify.breakpoint.smAndUp"
                                            :disabled="!valid"
                                    >Вход
                                    </v-btn>
                                </v-col>
                                <!--<v-col cols="12" xl="6" lg="6" md="6" xs="8" sm="8">-->
                                <!--<v-btn-->
                                <!--outlined-->
                                <!--dark-->
                                <!--block-->
                                <!--@click="goToRegistration"-->
                                <!--&gt;Регистрация-->
                                <!--</v-btn>-->
                                <!--</v-col>-->

                                <!--<h4 align="center">-->
                                <!--<router-link :to="'/restorePassword'">Забыли пароль?</router-link>-->
                                <!--</h4>-->
                            </v-row>
                        </v-card-text>


                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
    </v-main>
</template>

<script>
    import * as ERROR_CONST from '../../../share/const-strings';

    export default {
        name: 'Login',
        data() {
            return {
                username: '',
                password: '',
                valid: false,
                showPassword: false,
                showError: false,
                loader: false,
                descriptionError: '',
                token: '',
                rules: {
                    required: (v) => !!v || 'Это поле обязательно',
                    min: (v) => v.length >= 5 || 'Пароль должен быть больше 5 символов',
                    templateVal: (v) => /^[\w.@+-]+$/.test(v) || 'Только латинские буквы, цифры и символы @/./+/-/_.',
                },
            };
        },
        methods: {
            goToRegistration() {
                this.$router.push('/registration');
            },
            goToSystem() {
                if (!this.valid) {
                    return;
                }
                this.showError = false;
                this.loader = true;
                const user = {
                    username: this.username,
                    password: this.password,
                };

                this.$store.dispatch('logIn', user)
                    .then((response) => {
                        this.loader = false;
                        if (response.status === 200) {
                            localStorage.setItem('user-token', response.data.token);
                            this.$router.push('/contingent');
                        } else if (response.status === 400) {
                            this.showError = true;
                            switch (response.data.error) {
                                case ERROR_CONST.INCORRECT_DATA:
                                    this.descriptionError = ERROR_CONST.INCORRECT_DATA_RU;
                                default:
                                    this.descriptionError = err;
                            }
                        }
                    });
            },
        },
        computed: {
            timesOfDay() {
                const time = new Date().getHours();
                if (time >= 0 && time < 3) {
                    return 'Доброй ночи!';
                }

                if (time >= 3 && time < 11) {
                    return 'Доброе утро!';
                }

                if (time >= 11 && time < 17) {
                    return 'Добрый день!';
                }

                if (time >= 17 && time <= 23) {
                    return 'Добрый вечер!';
                }

                return 'Добро пожаловать!';
            },
        },
        beforeCreate() {
            if (localStorage.getItem('user-token')) {
                this.$router.push('/contingent');
            }
        },
    };
</script>

<style scoped>
    .profstyle {
      background: linear-gradient(45deg, #1b2b57, #1c426c, #1f5684, #1e6696);
    }
</style>