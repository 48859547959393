import {HTTP} from '@/plugins/axios';
import {print} from 'graphql';


export default {
    state: {
        unionCardItems: [],
        unionCardTotalItems: 0,
        unionCardLoaderItems: false,
    },
    getters: {
        getUnionCardItems(state: any) {
            return state.unionCardItems;
        },
        getUnionCardTotalItems(state: any) {
            return state.unionCardTotalItems;
        },
        getUnionCardLoaderItems(state: any) {
            return state.unionCardLoaderItems;
        },
    },
    mutations: {
        setUnionCardItems(state: any, payload: any) {
            state.unionCardItems = payload;
        },
        setUnionCardTotalItems(state: any, payload: any) {
            state.unionCardTotalItems = payload;
        },
        setUnionCardLoaderItems(state: any, payload: any) {
            state.unionCardLoaderItems = payload;
        },
    },
    actions: {
        async defaultLoadUnionCard({commit}: any, payload: any) {
            try {
                commit('setUnionCardLoaderItems', true);
                const AUTH_CONST: string = 'Authorization';
                const CONTENT_TYPE_CONST: string = 'Content-Type';
                HTTP.defaults.headers.common[AUTH_CONST] = 'Token ' + localStorage.getItem('user-token');
                HTTP.defaults.headers.common[CONTENT_TYPE_CONST] = 'application/json';
                const PRE_LOAD_UNION_CARD = require('../graphql/union_card/SearchUnionCard.gql');
                await HTTP.post('/graphql', {
                    query: print(PRE_LOAD_UNION_CARD),
                    variables: {
                        fullname: payload.fullname,
                        status: payload.statuses,
                        facultyId: payload.facultyId,
                        groupId: payload.groupId,
                        page: payload.page,
                        limit: payload.limit,
                    },
                }).then((response) => {
                        commit('setUnionCardItems', response.data.data.student.unionCard.getList.list);
                        commit('setUnionCardTotalItems', response.data.data.student.unionCard.getList.total);
                        commit('setUnionCardLoaderItems', false);
                    },
                );
            } catch (e) {
                commit('setUnionCardLoaderItems', false);
            }
        },
        async setStatusUnionCard({commit}: any, payload: any) {
            const AUTH_CONST: string = 'Authorization';
            const CONTENT_TYPE_CONST: string = 'Content-Type';
            HTTP.defaults.headers.common[AUTH_CONST] = 'Token ' + localStorage.getItem('user-token');
            HTTP.defaults.headers.common[CONTENT_TYPE_CONST] = 'application/json';
            const SET_STATUS_UNION_CARD = require('../graphql/union_card/SetStatusUnionCard.gql');
            await  HTTP.post('/graphql', {
                query: print(SET_STATUS_UNION_CARD),
                variables: {
                    id: payload.item.id,
                    data: {
                        issueDate: payload.item.issueDate,
                        payed: payload.item.payed,
                        status: payload.nextStatus,
                    },
                },
            }).then((response) => {
                payload.item.status = response.data.data.student.unionCard.update.status;
            });
        },
        async addPaymentUnionCard({commit}: any, payload: any) {
            const AUTH_CONST: string = 'Authorization';
            const CONTENT_TYPE_CONST: string = 'Content-Type';
            HTTP.defaults.headers.common[AUTH_CONST] = 'Token ' + localStorage.getItem('user-token');
            HTTP.defaults.headers.common[CONTENT_TYPE_CONST] = 'application/json';
            const SET_STATUS_UNION_CARD = require('../graphql/union_card/SetStatusUnionCard.gql');
            await HTTP.post('/graphql', {
                query: print(SET_STATUS_UNION_CARD),
                variables: {
                    id: payload.id,
                    data: {
                        issueDate: payload.issueDate,
                        payed: payload.payed,
                        status: payload.status,
                    },
                },
            });
        },
    },
};
