export default {
    state: {
        isShow: false,
        color: 'success',
        text: 'Hello world!',
        timeout: 2000,
    },
    getters: {
        getIsShowAlertSnackbar(state: any) {
            return state.isShow;
        },
        getColorAlertSnackbar(state: any) {
            return state.color;
        },
        getTextAlertSnackbar(state: any) {
            return state.text;
        },
    },
    mutations: {
        setIsShowAlertSnackbar(state: any, payload: any) {
            state.isShow = payload;
        },
        setColorAlertSnackbar(state: any, payload: any) {
            state.color = payload;
        },
        setTextAlertSnackbar(state: any, payload: any) {
            state.text = payload;
        },
    },
    actions: {
        showAlert({commit}: any, payload: any) {
            commit('setColorAlertSnackbar', payload.color);
            commit('setTextAlertSnackbar', payload.text);
            commit('setIsShowAlertSnackbar', payload.show);
        },
    },
};
