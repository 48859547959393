<template>
    <v-card>
        <v-card-title>
            <v-btn @click="closeDialog" icon>
                <v-icon>mdi-close</v-icon>
            </v-btn>
            Стипендия
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
            <div v-if="loader" class="text-center">
                <v-progress-circular
                        indeterminate
                        color="primary"
                ></v-progress-circular>
            </div>
            <div v-else>
                <v-row class="mt-5">
                    <p class="subtitle-1 font-weight-bold pl-2 pr-1" v-if="profile.sex === 'м'">
                        Студент: </p>
                    <p class="subtitle-1 font-weight-bold pl-2 pr-1" v-else>Студентка: </p>
                    <p class="subtitle-1">{{profile.getFullname()}}</p>
                </v-row>
                <v-row class="mt-n5 mb-n3">
                    <p class="subtitle-1 font-weight-bold pl-2 pr-2">Выплаты:</p>
                </v-row>
                <div v-if="hasStipendia(profile)">
                    <v-list-item transition="scroll-x-transition"
                                 v-for="item in profile.stipendia"
                                 three-line-line class="mt-n4">
                        <v-list-item-icon class="mr-n1">
                            <v-icon x-small>mdi-checkbox-blank-circle</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{item.name}}</v-list-item-title>
                            <v-list-item-subtitle v-if="item.semester">{{item.semester.name}}</v-list-item-subtitle>
                            <v-list-item-subtitle>Сумма: {{item.size+ ' ₽'}}</v-list-item-subtitle>
                            <v-list-item-subtitle v-if="item.optionalPeriodStart">Период: {{item.getStartDate()}} - {{item.getEndDate()}}</v-list-item-subtitle>

                        </v-list-item-content>
                    </v-list-item>
                </div>
                <div v-else class="text-center">
                    <p class="heading">Записи о выплатах отсутствуют</p>
                </div>
            </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn text
                   color="primary"
                   @click="closeDialog"
                   @keydown.esc="closeDialog">Закрыть
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import {UserProfile} from '@/model/dto/UserProfile';

    export default {
        name: 'DialogStipendia',
        props: {
            profileId: null,
        },
        data() {
            return {
                profile: new UserProfile(),
                loader: true,
            };
        },
        methods: {
            hasStipendia(item) {
                if (item.stipendia === undefined) {
                    return false;
                }

                return item.stipendia.length > 0;
            },
            closeDialog() {
                this.$emit('closeDialog');
            },
        },
        beforeMount() {
            this.loader = true;
            this.$store.dispatch('getUserProfileById', {
                id: this.profileId,
            }).then(() => {
                    this.profile = this.$store.getters.getUserProfile;
                    this.loader = false;
                },
            );
        },
    };
</script>

<style scoped>

</style>