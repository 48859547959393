<template>
    <v-card>
        <v-card-title>
            <v-btn
                    @click="closeDialog"
                    icon
                    @keydown.esc="closeDialog">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            Заявление на вступление
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="align-center justify-center text-center">
            <img style="width: 80%; height: auto; position: center" :src="statementSrc">
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        name: 'DialogStatement',
        props: {
            statementSrc: null,
        },
        methods: {
            closeDialog() {
                this.$emit('closeDialog');
            },
        },
    };
</script>

<style scoped>

</style>