<template>
    <div>
        <v-card>
            <v-card-title>Профиль</v-card-title>
            <v-card-text>

            </v-card-text>
            <pre>{{$store.getters.getMyProfile}}</pre>
        </v-card>
    </div>
</template>

<script>
    const USER_ID = 'userId';
    export default {
        name: 'Profile',
        data() {
            return {
                id: this.$route.params[USER_ID],
                myProfile: false,
            };
        },
        watch: {
            $route(toR, fromR) {
                this.myProfile = toR.name;
            },
        },
        beforeCreate() {
            this.myProfile = this.$store.getters.getMyProfile;
        },
    };
</script>

<style scoped>

</style>