import moment from 'moment';

interface SemesterDto {
    name?: string;
    year?: number;
    number?: number;
    start?: string;
    end?: string;
}

export class Semester {
    public static fromDto(
        dto: SemesterDto,
    ): Semester {
        return new Semester(
            dto.name,
            dto.year,
            dto.number,
            dto.start,
            dto.end,
        );
    }

    public name?: string;
    public start?: string;
    public end?: string;
    public year?: number;
    public number?: number;

    constructor(
        name: string | undefined,
        year: number | undefined = 0,
        number: number | undefined = 0,
        start: string | undefined,
        end: string | undefined,
    ) {
        this.name = name;
        this.year = year;
        this.number = number;
        this.start = start;
        this.end = end;
    }

    public getStartDate() {
        if (this.start === undefined) {
            return '- -';
        }
        return moment(this.start).locale('ru').format('L');
    }

    public getEndDate() {
        if (this.end === undefined) {
            return '---';
        }
        return moment(this.end).locale('ru').format('L');
    }
}
