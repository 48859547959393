import {Stipendia} from '@/model/dto/StipendiaDto';
import {Faculty} from '@/model/dto/Faculty';
import {Group} from '@/model/dto/GroupDto';
import moment from 'moment';
import {Speciality} from '@/model/dto/SpecialityDto';
import {STATUS_ACADEM, STATUS_ACTIVE, STATUS_GRADUATE, STATUS_WASTED} from '@/share/const-strings';
import {Semester} from '@/model/dto/SemesterDto';

interface UserProfileDto {
    fullname?: string;
    familyname?: string;
    firstname?: string;
    surname?: string;
    country?: string;
    status?: string;
    educationForm?: string;
    educationCondition?: string;
    mainPhoto?: string;
    sex?: string;
    isMember: boolean;
    blacklisted: boolean;
    memberFrom?: string;
    memberEnd?: string;
    entranceSemester?: Semester;
    graduateSemester?: Semester;
    stipendia?: Stipendia[];
    faculty?: Faculty;
    group?: Group;
    speciality?: Speciality;
    studentNumber?: string;
    birthday?: string;
    phone?: string;
    email?: string;
    elUnionCard?: string;
    sksBarcode?: string;
}

export class UserProfile {
    public static fromDto(
        dto: UserProfileDto,
    ): UserProfile {
        return new UserProfile(
            dto.fullname,
            dto.familyname,
            dto.firstname,
            dto.surname,
            dto.country,
            dto.status,
            dto.educationForm,
            dto.educationCondition,
            dto.mainPhoto,
            dto.sex,
            dto.isMember,
            dto.blacklisted,
            dto.memberFrom,
            dto.memberEnd,
            dto.entranceSemester,
            dto.graduateSemester,
            dto.stipendia,
            dto.faculty,
            dto.group,
            dto.speciality,
            dto.studentNumber,
            dto.birthday,
            dto.phone,
            dto.email,
            dto.elUnionCard,
            dto.sksBarcode,
        );
    }

    private static getRandomInt(min: number, max: number): number {
        return Math.floor(Math.random() * (max - min)) + min;
    }

    public fullname?: string;
    public familyname?: string;
    public firstname?: string;
    public surname?: string;
    public country?: string;
    public status?: string;
    public educationForm?: string;
    public educationCondition?: string;
    public mainPhoto?: string;
    public sex?: string;
    public isMember?: boolean;
    public blacklisted?: boolean;
    public memberFrom?: string;
    public memberEnd?: string;
    public entranceSemester?: Semester;
    public graduateSemester?: Semester;
    public stipendia?: Stipendia[];
    public faculty?: Faculty;
    public group?: Group;
    public speciality?: Speciality;
    public studentNumber?: string;
    public birthday?: string;
    public phone?: string;
    public email?: string;
    public elUnionCard?: string;
    public sksBarcode?: string;


    constructor(
        fullname?: string,
        familyname?: string,
        firstname?: string,
        surname?: string,
        country?: string,
        status?: string,
        educationForm?: string,
        educationCondition?: string,
        mainPhoto?: string,
        sex?: string,
        isMember?: boolean,
        blacklisted?: boolean,
        memberFrom?: string,
        memberEnd?: string,
        entranceSemester?: Semester,
        graduateSemester?: Semester,
        stipendia?: Stipendia[],
        faculty?: Faculty,
        group?: Group,
        speciality?: Speciality,
        studentNumber?: string,
        birthday?: string,
        phone?: string,
        email?: string,
        elUnionCard?: string,
        sksBarcode?: string,
    ) {
        this
            .fullname = fullname;
        this
            .familyname = familyname;
        this
            .firstname = firstname;
        this
            .surname = surname;
        this
            .country = country;
        this
            .status = status;
        this
            .educationForm = educationForm;
        this
            .educationCondition = educationCondition;
        this
            .mainPhoto = mainPhoto;
        this
            .sex = sex;
        this
            .isMember = isMember;
        this
            .blacklisted = blacklisted;
        this
            .memberFrom = memberFrom;
        this
            .memberEnd = memberEnd;
        this
            .entranceSemester = entranceSemester;
        this
            .graduateSemester = graduateSemester;
        this
            .stipendia = stipendia;
        this
            .faculty = faculty;
        this
            .group = group;
        this
            .speciality = speciality;
        this
            .studentNumber = studentNumber;
        this
            .birthday = birthday;
        this
            .phone = phone;
        this
            .email = email;
        this.elUnionCard = elUnionCard;
        this.sksBarcode = sksBarcode;
    }

    public getFullname(): string | undefined {
        return this.fullname;
    }

    public getFamilyname(): string | undefined {
        return this.familyname;
    }

    public getFirstname(): string | undefined {
        return this.firstname;
    }

    public getSurname(): string | undefined {
        return this.surname;
    }

    public getCountry(): string | undefined {
        return this.country;
    }

    public getStatus(): string | undefined {
        switch (this.status) {
            case STATUS_ACTIVE.value:
                return STATUS_ACTIVE.text;
            case STATUS_GRADUATE.value:
                return STATUS_GRADUATE.text;
            case STATUS_ACADEM.value:
                return STATUS_ACADEM.text;
            case STATUS_WASTED.value:
                return STATUS_WASTED.text;
            default:
                return 'У-у-у-пс, непонятный статус';
        }
    }

    public getEducationForm():
        string | undefined {
        return this.educationForm;
    }

    public getEducationCondition()
        :
        string | undefined {
        return this.educationCondition;
    }

    public getEntranceDate()
        :
        string | undefined {
        if (this.entranceSemester?.start === undefined) {
            return 'Отсутствует';
        }
        return moment(this.entranceSemester?.start).locale('ru').format('L');
    }

    public getGraduateDate()
        :
        string | undefined {
        if (this.graduateSemester?.end === undefined) {
            return 'Отсутствует';
        }
        return moment(this.graduateSemester?.end).locale('ru').format('L');
    }

    public getStudentNumber()
        :
        string | undefined {
        if (this.studentNumber === null) {
            return 'Отсутствует';
        }
        return this.studentNumber;
    }

    public getPhoneNumber()
        :
        string | undefined {
        if (this.phone === null) {
            return 'Отсутствует';
        }
        return this.phone;
    }

    public getEmail()
        :
        string | undefined {
        if (this.email === null) {
            return 'Отсутствует';
        }
        return this.email;
    }

    public getBirthday()
        :
        string | undefined {
        return moment(this.birthday).locale('ru').format('L');
    }

    public getOld()
        :
        string | undefined {
        const old = moment().diff(this.birthday, 'year');
        if (old.toString().substr(1, 1) === '1') {
            return old + ' год';
        } else if (old.toString().substr(1, 1) === '2' || old.toString().substr(1, 1) === '3' || old.toString().substr(1, 1) === '4') {
            return old + ' года';
        } else {
            return old + ' лет';
        }
    }

    public getFaculty()
        :
        Faculty | undefined {
        if (this.faculty === undefined) {
            return new Faculty();
        }
        return this.faculty;
    }

    public getGroup()
        :
        Group | undefined {
        if (this.group === undefined) {
            return new Group();
        }
        return this.group;
    }

    public getSpeciality()
        :
        Speciality | undefined {
        if (this.speciality === undefined) {
            return new Speciality();
        }
        return this.speciality;
    }

    public getMainPhotoSrc()
        :
        string | undefined {
        if (this.mainPhoto !== null) {
            return this.mainPhoto;
        }

        if (this.sex === 'м') {
            return require('@/assets/avatar/male/' + UserProfile.getRandomInt(1, 10) + '.jpg');
        } else {
            return require('@/assets/avatar/female/' + UserProfile.getRandomInt(1, 7) + '.jpg');
        }
    }

    public getIsMember()
        :
        boolean | undefined {
        return this.isMember;
    }

    public getIsMemberText()
        :
        string | undefined {
        if (this.isMember) {
            return 'Да';
        } else {
            return 'Нет';
        }
    }

    public getIsBlackList()
        :
        boolean | undefined {
        return this.blacklisted;
    }

    public getIsBlackListText()
        :
        string | undefined {
        if (this.blacklisted) {
            return 'Да';
        } else {
            return 'Нет';
        }
    }

    public getMemberFromDate()
        :
        string | undefined {
        if (this.memberFrom === undefined || this.memberFrom === null) {
            return 'Отсутствует';
        }
        return moment(this.memberFrom).locale('ru').format('L');
    }

    public getMemberEndDate()
        :
        string | undefined {
        if (this.memberEnd === null || this.memberEnd === undefined) {
            return 'Отсутствует';
        }
        return moment(this.memberEnd).locale('ru').format('L');
    }

    public getElUnionCard()
        :
        string | undefined {
        if (this.elUnionCard == null) {
            return 'Отсутствует';
        }
        return this.elUnionCard;
    }

    public getSksBarcode()
        :
        string | undefined {
        if (this.sksBarcode === null) {
            return 'Отсутствует';
        }
        return this.sksBarcode;
    }
}
