<template>
  <div>
    <v-card
        class="ma-xl-1 ma-sm-2 ma-xs-2"
        outlined
        min-height="90vh">
      <v-card-text>
        <v-breadcrumbs
            large
            :items="breadCrumbsItems">
        </v-breadcrumbs>
        <v-divider></v-divider>
        <v-row>
          <v-col cols="12" xl="2" lg="2" md="4" sm="12" xs="12">
            <v-card min-width="200">
              <v-img
                  aspect-ratio="1"
                  :src="profile.getMainPhotoSrc()"
              >
              </v-img>

              <v-list two-line>
                <v-divider light></v-divider>
                <div class="text-center body-1 mt-2">
                  {{ profile.getFullname() }}
                </div>
                <v-list-item v-if="profile.getFaculty() !== null">
                  <v-list-item-icon>
                    <v-icon color="primary"> mdi-bank</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ profile.getFaculty().getShortName() }}</v-list-item-title>
                    <v-list-item-subtitle>Факультет</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <!--        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>ИиВТ</v-list-item-title>
                                <v-list-item-subtitle>Специальность</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>-->
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary"> mdi-library</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-html="profile.getGroup().getName()"></v-list-item-title>
                    <v-list-item-subtitle>Группа</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-cake-variant</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-html="profile.getBirthday()"></v-list-item-title>
                    <v-list-item-subtitle>День рождения</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-credit-card-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-html="profile.getElUnionCard()"></v-list-item-title>
                    <v-list-item-subtitle>Номер профбилета</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-counter</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-html="profile.getStudentNumber()"></v-list-item-title>
                    <v-list-item-subtitle>Номер зачётки</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-phone</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-html="profile.getPhoneNumber()"></v-list-item-title>
                    <v-list-item-subtitle>Номер телефона</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-email</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-html="profile.getEmail()"></v-list-item-title>
                    <v-list-item-subtitle>E-mail</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>


            </v-card>
            <!--                            <v-btn text small color="success" disabled>Добавить фотографию</v-btn>-->
            <!--                            <br>-->
            <!--                            <v-btn text small color="red" disabled>Удалить фотографию</v-btn>-->
            <!--                            <br>-->
            <!--                            <v-btn text small color="primary" disabled>Изменить фотографию</v-btn>-->
          </v-col>
          <v-col cols="12" xl="10" lg="10" md="8" sm="12" xs="12">
            <div>
              <v-tabs>
                <v-tab>
                  <v-icon left>mdi-account</v-icon>
                  Дополнительная информация
                </v-tab>
                <!--<v-tab disabled>
                    <v-icon left>mdi-bitcoin</v-icon>
                    Стипендия
                </v-tab>
                <v-tab disabled>
                    <v-icon left>mdi-cash</v-icon>
                    Взносы
                </v-tab>
                <v-tab disabled>
                    <v-icon left>mdi-shield-edit</v-icon>
                    Доступ
                </v-tab>
                <v-tab disabled>
                    <v-icon left>mdi-hammer-wrench</v-icon>
                    Настройки
                </v-tab>-->
                <v-tab-item>
                  <v-divider></v-divider>
                  <information-card :profile="profile"></information-card>
                </v-tab-item>
                <v-tab-item>
                  <v-divider></v-divider>
                  <v-card-text class="fill-height"></v-card-text>
                </v-tab-item>
                <v-tab-item>
                  <v-divider></v-divider>
                  <v-card-text class="fill-height"></v-card-text>
                </v-tab-item>
                <v-tab-item>
                  <v-divider></v-divider>
                  <v-card-text class="fill-height"></v-card-text>
                </v-tab-item>
                <v-tab-item>
                  <v-divider></v-divider>
                  <v-card-text class="fill-height"></v-card-text>
                </v-tab-item>
              </v-tabs>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

import {UserProfile} from '@/model/dto/UserProfile';
import InformationCard from '@/components/system/user/profile/components/InformationCard';

const USER_ID = 'userId';
export default {
  name: 'UserProfile',
  components: {InformationCard},
  data() {
    return {
      id: this.$route.params[USER_ID],
      breadCrumbsItems: [
        {
          text: 'Контингент',
          disabled: false,
          to: '/contingent',
        },
        {
          text: 'Профиль пользователя',
          disabled: true,
        },
      ],
      profile: new UserProfile(),
      userProfile: false,
    };
  },
  watch: {
    $route(toR, fromR) {
      this.userProfile = toR.name;
    },
  },
  mounted() {
    this.$store.dispatch('getUserProfileById', {
      id: this.id,
    }).then(() => {
          this.profile = this.$store.getters.getUserProfile;
        },
    );
  },
};
</script>

<style scoped>

</style>