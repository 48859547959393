<template>
	<div>
<!--		<v-container fluid fill-height>
			<v-layout align-center justify-center>
				<v-card>
					<v-toolbar
							color="primary"
							dense
							dark
							elevation="1">
						<v-toolbar-title class="title">Анкета</v-toolbar-title>
					</v-toolbar>
					<v-card-text>
						<v-form>

							<v-text-field
									v-model="familyname"
									label="Фамилия"
									readonly></v-text-field>

							<v-text-field
									v-model="firstname"
									label="Имя"
									readonly></v-text-field>

							<v-text-field
									v-model="surname"
									label="Отчество"
									readonly></v-text-field>

							<v-menu
									ref="menuBirthdayDate"
									v-model="menuBirthdayDate"
									:close-on-content-click="false"
									transition="scale-transition"
									offset-y
									full-width
									max-width="290px"
									min-width="290px"
							>
								<template v-slot:activator="{ on }">
									<v-text-field
											label="Дата рождения"
											v-model="computedDateFormatted"
											v-on="on"
											readonly
									></v-text-field>
								</template>
								<v-date-picker
										locale="ru-ru"
										ref="picker"
										v-model="birthday"
										:max="new Date().toISOString().substr(0, 10)"
										min="1950-01-01"
										@change="saveBirthdayDate"
								></v-date-picker>
							</v-menu>

							<v-row>
								<v-col cols="12" lg="4" xl="4" md="6" sm="12" xs="12">
									<v-text-field
											v-model="passportSerial"
											label="Серия паспорта"
									></v-text-field>
								</v-col>
								<v-col cols="12" lg="4" xl="4" md="6" sm="12" xs="12">
									<v-text-field
											v-model="passportNumber"
											label="Номер паспорта"
									></v-text-field>
								</v-col>
								<v-col cols="12" lg="4" xl="4" md="12" sm="12" xs="12">
									<v-menu
											ref="menuPassportDate"
											v-model="menuPassportDate"
											:close-on-content-click="false"
											transition="scale-transition"
											offset-y
											full-width
											max-width="290px"
											min-width="290px"
									>
										<template v-slot:activator="{ on }">
											<v-text-field
													label="Дата выдачи"
													v-model="computedDateFormattedPassport"
													v-on="on"
													readonly
											></v-text-field>
										</template>
										<v-date-picker
												locale="ru-ru"
												ref="picker"
												v-model="passportIssuedData"
												:max="new Date().toISOString().substr(0, 10)"
												min="1950-01-01"
												@change="savePassportDate"
										></v-date-picker>
									</v-menu>
								</v-col>
								<v-col cols="12">
									<v-text-field
											v-model="passportIssuedBy"
											label="Кем выдан"
									></v-text-field>
								</v-col>
							</v-row>
							<v-text-field
									label="Место регистрации по паспорту"
									v-model="registration_address"></v-text-field>
							<v-text-field
									v-model="fact_address"
									label="Фактическое место проживания"></v-text-field>
						</v-form>
					</v-card-text>
					<v-card-actions>
						<div class="flex-grow-1"></div>
						<v-btn
								text
								color="primary"
								@click="sendQuest"
						>Отправить
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-layout>
		</v-container>-->
<!--		<v-row align="center" justify="center">
			<v-card
					class="mt-6"
					flat
					max-width="600"
			>
				<v-img
						:src="require('@/assets/at_work.png')"
				></v-img>

				<v-card-title>
					<span class="grey&#45;&#45;text subtitle-1">Воронченко, Семёнов и Нечай-Гумен работают над этой функцией</span>
				</v-card-title>
			</v-card>
		</v-row>-->
	</div>
</template>

<script>
    import moment from 'moment';

    export default {
        name: 'Questionnaire',
        data() {
            return {
                menuBirthdayDate: false,
                menuPassportDate: false,
                myId: null,
                familyname: null,
                firstname: null,
                passportSerial: null,
                passportNumber: null,
                passportIssuedBy: null,
                passportIssuedData: new Date().toISOString().substr(0, 10),
                birthday: new Date().toISOString().substr(0, 10),
                surname: null,
                registration_address: null,
                fact_address: null,
                student_number: null,
            };
        },
 /*       watch: {
            menuBirthdayDate(val) {
                val && this.$nextTick(() => (this.$refs.picker.activePicker = 'YEAR'));
            },
            menuPassportDate(val) {
                val && this.$nextTick(() => (this.$refs.picker.activePicker = 'YEAR'));
            },
        },*/
        computed: {
            computedDateFormatted() {
                moment.locale('ru');
                return this.birthday ? moment(this.birthday).format('L') : '';
            },
            computedDateFormattedPassport() {
                moment.locale('ru');
                return this.passportIssuedData ? moment(this.passportIssuedData).format('L') : '';
            },
        },
        methods: {
            sendQuest() {
                const data = {
                    student: this.myId,
                    passport: {
                        serial: this.passportSerial.trim(),
                        number: this.passportNumber.trim(),
                        issued_by: this.passportIssuedBy,
                        issued_date: this.passportIssuedData,
                    },
                    birthday: this.birthday,
                    need_sanatory: this.need_sanatory,
                    illness: this.illness,
                    registration_address: this.registration_address,
                    fact_address: this.fact_address,
                };
                this.$store.dispatch('sendQuestionnaire', data);
                this.$store.dispatch('patchStudentInfo', data);
            },
            // Для хранения даты рождения
            saveBirthdayDate(date) {
                this.$refs.menuBirthdayDate.save(date);
            },
            // Для хранения даты выдачи паспорта
            savePassportDate(date) {
                this.$refs.menuPassportDate.save(date);
            },
        },

/*        beforeCreate() {
            this.$store.dispatch('loadMyProfile')
                .then(() => {
                    let myProfile = this.$store.getters.getMyProfile;
                    this.myId = myProfile.id;
                    this.familyname = myProfile.familyname;
                    this.firstname = myProfile.firstname;
                    this.surname = myProfile.surname;
                    this.student_number = myProfile.student_number
                });
        }*/
    };
</script>

<style scoped>

</style>